<template>
    <!--    <ServiceUnavailableWidget v-bind:accountInfo="accountInfo"/>-->
    <div id="app_child">
        <div class="main-wrapper">
            <div class="dash__board__wrapper">
                <aside class="aside">
                    <div class="logo_block"><a :href="route('dashboard')"><img src="/img/logo.svg" alt="logo"></a></div>
                    <div class="main_nav" data-main_nav>
                        <div class="main_nav__inner">
                            <div class="main_nav_item">
                                <Link
                                    class="main_link"
                                    as="button"
                                    :class="{'active':route().current('dashboard')}"
                                    :href="route('dashboard')"
                                    :disabled="isDisabled"
                                >
                                    <div>
                                        <span class="fm-layout"></span>
                                    </div>
                                    <span class="title">{{ __("global.hint.dashboard.title") }}</span>
                                </Link>
                            </div>
                            <div class="main_nav_item" :class="onlyDashboard ? 'disabled-a-in' : ''">
                                <Link
                                    class="main_link"
                                    as="button"
                                    :class="{'active':route().current('products')}"
                                    :href="route('products')"
                                    :disabled="isDisabled"
                                >
                                    <div>
                                        <span class="fm-clipboard"></span>
                                    </div>
                                    <span class="title">{{ __("global.hint.products.title") }}</span>
                                </Link>
                            </div>
                            <div class="main_nav_item" :class="onlyDashboard ? 'disabled-a-in' : ''">
                                <Link
                                    class="main_link"
                                    as="button"
                                    :class="{'active':route().current('expenses')}"
                                    :href="route('expenses')"
                                    :disabled="isDisabled"
                                >
                                    <div>
                                        <span class="fm-credit-card"></span>
                                    </div>
                                    <span class="title">{{ __("global.hint.expenses.title") }}</span>
                                </Link>
                            </div>
                            <div
                                class="main_nav_item"
                                :class="onlyDashboard ? 'disabled-a-in' : ''"
                                v-if="isAvailable('standard') || isAvailable('business')"
                            >
                                <Link
                                    class="main_link"
                                    as="button"
                                    :class="{'active':route().current('redeems')}"
                                    :href="route('redeems')"
                                    :disabled="isDisabled"
                                >
                                    <div>
                                        <span class="fm-rotate-ccw"></span>
                                    </div>
                                    <span class="title">{{ __("global.hint.redeems.title") }}</span>
                                </Link>
                            </div>
                            <div class="main_nav_item" :class="onlyDashboard ? 'disabled-a-in' : ''" v-if="isAvailable('business')">
                                <Link
                                    class="main_link"
                                    as="button"
                                    :class="{'active':route().current('warehouse')}"
                                    :href="route('warehouse')"
                                    :disabled="isDisabled"
                                >
                                    <div>
                                        <span class="fm-archive"></span>
                                    </div>
                                    <span class="title">{{ __("global.hint.warehouse.title") }}</span>
                                </Link>
                            </div>
                            <div class="main_nav_item main_nav_item_parent" :class="{'active': settingsNavOpen}">
                                <button class="main_link" @click="toggleSettingsNav">
                                    <div>
                                        <span class="fm-settings"></span>
                                    </div>
                                    <span class="title">{{ __("global.hint.settings.title") }}</span>
                                </button>
                                <div class="main_nav_item__innerBackdrop" v-if="settingsNavOpen" @click="toggleSettingsNav"></div>
                                <div class="main_nav_item__inner" :class="{'active': settingsNavOpen}"
                                     data-settings-dropdown>
                                    <div class="main_nav_item__innerBody">
                                        <Link
                                            class="main_linkInner"
                                            as="button"
                                            :class="{'active':route().current('settings')}"
                                            :href="route('settings')"
                                        >
                                            <div>
                                                <span class="main_linkInner__icon fm-sliders"></span>
                                            </div>
                                            <span class="title">{{ __("global.hint.settings.secondaryTitle") }}</span>
                                        </Link>
                                        <Link class="main_linkInner" :class="{'active':route().current('pricing')}"
                                            :href="route('pricing')">
                                            <div>
                                                <span>
                                                    <svg class="main_linkInner__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7 13H14C16.7614 13 19 10.7614 19 8V8C19 5.23858 16.7614 3 14 3H7V13ZM7 13H5M7 13V21M5 17H13"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <span class="title">{{ __("global.hint.pricing.title") }}</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
                <div class="auth_row">
                    <div class="auth_row_inner">
                        <div class="auth_title_block">
                            <button class="tf_mobile__switcher js_trigger__click"
                                    data-element=".aside, .dash__board__container, .auth_row, .buttons-panel"
                                    data-toggle="false"
                                    type="button" aria-label="Меню">
                                <!-- <span class="mbc"></span>
                                <span class="mbc"></span>
                                <span class="mbc"></span> -->

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-menu">
                                    <line x1="3" y1="12" x2="21" y2="12"></line>
                                    <line x1="3" y1="6" x2="21" y2="6"></line>
                                    <line x1="3" y1="18" x2="21" y2="18"></line>
                                </svg>
                            </button>
                            <div class="page_title_outer">
                                <h1 class="page_title">{{ sectionTitle }}</h1>

                                <div v-if="store.beta" class="page-beta" v-tippy="__('Бета тестирование')">
                                    <img class="page-beta__img" src="/img/beta-test.svg" alt="">
                                    <div class="page-beta__inner">Бета<br>тестирование</div>
                                </div>
                            </div>
                            <div class="header-tabs"
                                 v-if="isAvailable('business') && (route().current('dashboard') || route().current('DashboardGeneral'))">
                                <button class="header-tabs__btn" type="button" @click="toggleHeaderTab('grid')"
                                        :class="tab === 'grid' ? 'active': ''">
                                    <span class="header-tabs__ico fm-grid"></span>
                                    <span class="header-tabs__text">Плитки</span>
                                </button>
                                <button class="header-tabs__btn" type="button" @click="toggleHeaderTab('chart')"
                                        :class="tab === 'chart' ? 'active': ''">
                                    <span class="header-tabs__ico fm-trending-up"></span>
                                    <span class="header-tabs__text">Диаграмма</span>
                                </button>
                            </div>
                        </div>
                        <ul class="auth_row_inner_nav">
                            <NotificationList :notifications="standardNotifications"/>
                            <li class="user_auth_dropdown">
                                <BreezeDropdown>
                                    <template #trigger>
                                        <div class="user_block">
                                            <DataSourceIcon
                                                :data-source="store.account.data_source ?? ''"
                                                :show-data-source="route().current() !== 'DashboardGeneral'"
                                            />
                                            <!--                                            <div class="user_account_status">{{ $page.props.auth.user.name }}</div>-->
                                            <div class="user_account_status">
                                                <div>{{ $page.props.auth.user.name }}</div>
                                                <div class="user_account_current"
                                                     v-if="isAvailable('business') && route().current() === 'DashboardGeneral'">
                                                    {{ 'Сводный Дэшборд' }}
                                                </div>
                                                <div class="user_account_current"
                                                     v-else-if="isAvailable('business')">
                                                    {{ $page.props.auth.data.current_account.title ?? 'Основной аккаунт' }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <template #content>
                                        <div class="scrollbar" v-if="isAvailable('business')">
                                            <div v-if="route().current() === 'DashboardGeneral'"
                                                 class="user_auth_nav_item active">
                                                <a class="user_auth_nav_item_link"
                                                   :href="route('DashboardGeneral')"><span>Сводный дэшборд</span></a>
                                            </div>

                                            <div
                                                v-for="account in store.accountList"
                                                :class="accountListDropdownItemClass(account)"
                                            >
                                                <a
                                                    class="user_auth_nav_item_link cursor-pointer"
                                                    @click="changeAccount(account.id)"
                                                >
                                                    <span>{{ account.title ?? 'Основной аккаунт' }}</span>
                                                </a>
                                            </div>

                                            <div
                                                v-if="route().current() != 'DashboardGeneral' && store.accountList.length > 1"
                                                class="user_auth_nav_item">
                                                <a class="user_auth_nav_item_link"
                                                   :href="route('DashboardGeneral')"><span>Сводный дэшборд</span></a>
                                            </div>

                                        </div>
                                        <div
                                            class="user_auth_nav_item user_auth_nav_item_ico"
                                            v-if="isAvailable('business')"
                                        >
                                            <a class="user_auth_nav_item_link" :href="route('connecting') + '?new'">
                                                <span>Добавить магазин</span>
                                            </a>
                                        </div>
                                        <div class="user_auth_nav_item logout">
                                            <a href="#" @click="logout"
                                               class="user_auth_nav_item_link"><span>Выйти</span></a>
                                        </div>
                                    </template>
                                </BreezeDropdown>
                            </li>
                        </ul>
                    </div>
                </div>
                <slot/>
            </div>
        </div>
        <footer class="data_loader_open">
            <div class="feedback_block">
                <p>
                    По любым вопросам пишите нам на:<br>
                    <a href="mailto:support@sellerdata.ru">support@sellerdata.ru</a> или в <a
                    href="https://t.me/sellerdata" target="_blank">Telegram</a>
                </p>
            </div>
        </footer>

        <div class="modal modal-basic logout-confirm" id="modalLogoutConfirm" tabindex="-1"
             aria-labelledby="modal_notificationDelete_label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title">Подтверждение</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="logout-confirm__title text-center">Вы уверены что хотите выйти?</p>
                    </div>
                    <div class="modal-footer">
                        <button data-dismiss="modal" aria-label="Close" class="btn-default">Отменить</button>
                        <button aria-label="Close" @click="logoutConfirm" class="btn-default btn-regular">Да</button>
                    </div>
                </div>
            </div>
        </div>
        <DashboardVideoModal></DashboardVideoModal>
        <Toast v-if="!ignoreNotif" :notifications="toastNotifications" :account-info="accountInfo"/>
        <NotificationModal :notifications="modalNotifications"/>
        <Modal v-if="showOutdatedWarning">
            <template #title>
                <h5 class="h4 modal-title">Внимание!</h5>
            </template>
            <template #body>
                <p>Ваша версия приложения устарела. Пожалуйста, обновите страницу.</p>
            </template>
            <template #buttons>
                <button @click="reload" class="btn-default btn-regular">Обновить</button>
            </template>
        </Modal>
    </div>
</template>

<script>

import BreezeDropdown from '@/Components/Dropdown.vue'
import BreezeDropdownLink from '@/Components/DropdownLink.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Toast from '@/Components/Notification/Toast.vue'
import NotificationList from '@/Components/Notification/NotificationList.vue'
import DashboardVideoModal from '@/Components/Dashboard/DashboardVideoModal.vue'
import { Inertia } from '@inertiajs/inertia'
import ServiceUnavailableWidget from '@/Components/Common/ServiceUnavailableWidget'
import NotificationModal from '@/Components/Notification/Modal'
import * as Pusher from 'pusher-js'
import { store } from '@/store'
import Modal from '@/Components/Common/Modal.vue'
import DataSourceIcon from '@/Components/Account/DataSourceIcon.vue'

export default {
    components: {
        DataSourceIcon,
        Modal,
        ServiceUnavailableWidget,
        BreezeDropdown,
        BreezeDropdownLink,
        Link,
        Toast,
        NotificationModal,
        NotificationList,
        DashboardVideoModal
    },
    props: {
        ignoreNotif: {
            type: Boolean,
            default: false,
        },
        onlyDashboard: {
            type: Boolean,
            default: false,
        },
        tab: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            accountInfo: {},
            showOutdatedWarning: false,
            showingNavigationDropdown: false,
            pusherStatus: false,
            pusher: {},
            //tabSelected:'',
            // settingsNavOpen: this.route().current('pricing') || this.route().current('settings'),
            settingsNavOpen: false,
            store
        }
    },

    emits: ['loading', 'setAccountInfo', 'toggleHeaderTab'],

    computed: {
        sectionTitle: function () {
            if (this.route().current() === 'settings') {
                return this.__("global.hint.settings.secondaryTitle");
            } else if (this.route().current() === 'pricing') {
                return this.__("global.hint.pricing.title");
            } else {
                return this.__("global.hint." + this.route().current() + ".title");
            }
        },
        toastNotifications: function () {
            return this.store.notifications.filter(item => item.type === 'toast');
        },
        standardNotifications: function () {
            return this.store.notifications.filter(item => item.type === 'standard');
        },
        modalNotifications: function () {
            return this.store.notifications.filter(item => item.type === 'modal');
        },
        isDisabled: function () {
            return !this.store.subscription.active;
        },
        isSupported: function () {
            return this.store.account.data_source === 'wildberries';
        }
    },

    methods: {
        accountListDropdownItemClass(account) {
            return {
                'user_auth_nav_item': true,
                'user_auth_nav_item_ico': true,
                'user_auth_nav_item_ozon': account.account.data_source === 'ozon',
                'user_auth_nav_item_wb': account.account.data_source === 'wildberries',
                'active': account.id === this.store.currentAccount && this.route().current() !== 'DashboardGeneral'
            }
        },
        changeAccount(id) {
            axios.post(this.route('changeAccount'), {id: id})
                .then(response => {
                    window.location.href = '/dashboard';
                })
                .catch(() => window.location.href = '/dashboard');
        },
        isAvailable: function (packageType) {
            return this.store.subscription.trial || store.subscription.type === packageType;
        },
        logout: function () {
            $('#modalLogoutConfirm').modal();
            return false;
        },
        logoutConfirm: function () {
            $('#modalLogoutConfirm').modal('hide');

            if (typeof window.accountInfoInterval !== 'undefined') {
                clearInterval(window.accountInfoInterval);
                delete window.accountInfoInterval;
            }

            Inertia.visit(this.route('logout'), {
                method: 'get'
            });
        },
        jsActiveTrigger: function () {
            let opened = false,
                dataToggle = '',
                $element = '',
                $this = '';
            let clickHandler = 'click';
            if ('ontouchstart' in document.documentElement) {
                clickHandler = 'touchend';
            }
            $(document).on(clickHandler, '.js_trigger__click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $this = $(this);
                if ($this.hasClass('tf_mobile__switcher')) {
                    $('body , html').toggleClass('fixed__body');
                }
                dataToggle = $(this).attr('data-toggle');
                if (typeof dataToggle === 'undefined') dataToggle = true;
                let element = $(this).attr('data-element');
                if (typeof element !== 'undefined') $element = $(element);

                if (dataToggle && dataToggle !== 'false') {
                    $element.slideToggle('slow');
                } else {
                    $element.toggleClass('active');
                }
                $(this).toggleClass('active');
                opened = !!$(this).hasClass('active');
            });
            $(document).on(clickHandler, function (e) {
                let notifyBar = $('.user_auth_dropdown');
                if ($(e.target).is(notifyBar) === false && notifyBar.has(e.target).length === 0) {
                    $(".user_auth_dropdown").removeClass("active");
                    $('.user_block').removeClass("active");
                }
            });
            $(document).on(clickHandler, '.hideText, .close-loader', null, function () {
                $(this).parent().fadeOut();
            });
            $(document).on(clickHandler, '.icon-delete, .close_info', null, function () {
                $(this).parent().parent().fadeOut();
            });
            $(document).on(clickHandler, '.hide_banner', null, function () {
                $(this).parent().parent().parent().fadeOut();
            });
            $(document).on('click', '.expenses-table tbody tr:not(.total_table_row) td:not(.action-td)', null, function () {
                $('#modal_change').modal('show');
            });
            $(document).on(clickHandler, '#mytable button.move', null, function () {
                let row = $(this).closest('table').find('tbody tr');
                if (row.hasClass('up'))
                    row.prev().before(row);
                else
                    row.next().after(row);
            });
        },
        setMenuDefaultState: function () {
            $('body , html').removeClass('fixed__body');
            $('.tf_mobile__switcher').removeClass('active');
        },

        fetchAccountInfo: async function () {
            this.store.loaded = false;

            await axios.get('/account/info')
                .then(response => {
                    this.$page.props.auth.data.in_updating = response.data.in_updating;
                    this.$page.props.auth.data.status = response.data.status;
                    this.$page.props.auth.data.last_sync = response.data.last_sync;
                    this.$page.props.auth.data.orders_updating = response.data.orders_updating;
                    this.$page.props.auth.data.orders_updating_first_month = response.data.orders_updating_first_month;

                    this.$page.props.auth.data.report_loaded = response.data.report_loaded;

                    this.accountInfo = response.data

                    const currentVersion = window.sellerdata_version ?? null;

                    if (response.data.version !== currentVersion && this.showOutdatedWarning === false) {
                        this.showOutdatedWarning = true;
                    }

                    this.store.stableDataEndDate = response.data.stable_data_end_date;
                    this.store.account = response.data;
                    this.store.userId = response.data.owner.id;
                    this.store.subscription = response.data.owner.subscription;
                    this.store.currentPayment = response.data.owner.current_payment || null;
                    this.store.notifications = response.data.notifications;
                    this.store.accountList = response.data.owner.account_list;
                    this.store.currentAccount = response.data.owner.current_account_id;
                    this.store.loaded = true;
                    this.store.currency = response.data.currency;

                    this.$emit('loading', !!response.data.in_updating);
                    this.$emit('setAccountInfo', this.accountInfo);
                    if (!this.pusherStatus) {
                        this.pusherStart();
                    }
                })
                .catch((e) => console.log(e));
        },
        pusherStart() {
            if (this.pusherStatus) {
                return;
            }
            this.pusherStatus = true;

            this.pusher = new Pusher(process.env.MIX_PUSHER_APP_KEY, {
                cluster: 'eu'
            });

            let channel = this.pusher.subscribe('account-' + this.$page.props.auth.user.user_account.account_id + '-status');
            channel.bind('account-status', function (data) {
                // data.id - account ID, can be added a check for account ID
                // data.version - APP version to force user reload app

                if (data.updated) {
                    this.fetchAccountInfo();
                }
            }, this);
        },

        toggleHeaderTab(tab) {
            this.$emit('toggleHeaderTab', tab);
        },
        toggleSettingsNav: function () {
            this.settingsNavOpen = !this.settingsNavOpen;
            // setTimeout(() => {
            //     if (this.settingsNavOpen) {
            //         var mainMenu = document.querySelector('[data-main_nav]');
            //         var scrollEl = document.querySelector('[data-settings-dropdown]').offsetTop;
            //         mainMenu.scroll({top: scrollEl, behavior: 'smooth'});
            //     }
            // }, 50);
        },
        reload() {
            window.location.reload();
        }
    },
    beforeMount() {
        this.fetchAccountInfo();
    },
    mounted() {
        this.setMenuDefaultState();
        if (typeof window.isComponentsMounted == 'undefined') {
            this.jsActiveTrigger();
            window.isComponentsMounted = true;
        }
    },
    unmounted() {
        try {
            this.pusher.disconnect();
        } catch (err) {
            console.log(err);
        }
    },
}
</script>
