<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="summaryModal" tabindex="-1" aria-labelledby="moreInfoModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-back" type="button" aria-label="Back" data-dismiss="modal">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <h5 v-if="brandIsSelected" id="moreInfoModalTitle" class="modal-title">Бренд "{{ product.brand ?? 'Без бренда' }}"</h5>
                    <h5 v-else class="modal-title" id="moreInfoModalTitle">Товары</h5>
                    <button type="button" class="modal-close" aria-label="Close" data-dismiss="modal">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div class="info-list">
                        <div v-if="!product.g_number && !brandIsSelected" class="info-list__header">
                            <img :src="product.image" alt="title" class="info-list__header__img" height="40" width="40">
                            <div class="info-list__header__body">
                                <div class="info-list__header__title">{{ product.title }}</div>
                                <div>
                                    <span class="item__product-id">
                                        <button class="copy-btn copy_button" :data-id="product.nmId" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                        <a v-if="product.link" class="item__link" :href="product.link" target="_blank">{{ product.nmId }}</a>
                                        <span v-else>{{ product.nmId }}</span>
                                    </span>
                                    <span v-if="product.supplier_article">/ {{ product.supplier_article }} </span>
                                    / {{ product.barcode }}
                                </div>
                                <div v-if="summary.countries">
                                    Страны: {{ summary.countries.join(', ') }}
                                </div>
                            </div>
                        </div>
                        <div v-else-if="product.g_number" class="info-list__header">
                            <div class="info-list__header__body">
                                <div>
                                    <span class="item__product-id">
                                        <button class="copy-btn copy_button" :data-id="product.g_number" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                        {{ product.g_number }}
                                    </span>
                                    <br />
                                    {{ product.date }}
                                </div>
                            </div>
                        </div>
                        <div v-if="!product.g_number && !brandIsSelected" class="info-list__subheader">
                            <div class="info-list__subheader__column">
                                <button class="info-list__subheader__item info-list__subheader__item_label open_price_popover" :data-id="product.id" :data-cost="product.cost" type="button">
                                    Себест.: <span :id="'product-cost-'+product.id">{{ (summary.costList && summary.costList.length) ? summary.costList.join(', ') : product.cost }}</span>&nbsp;₽
                                </button>
                                <div class="info-list__subheader__item info-list__subheader__item_value">Цена: <Currency :value="product.price"/></div>
                            </div>
                            <div class="info-list__subheader__column">
                                <button class="info-list__subheader__item info-list__subheader__item_label" v-tippy="balanceLabel">Остаток:</button>

                                <div class="info-list__subheader__item info-list__subheader__item_value">{{ product.quantity ? product.quantity : 0 }}</div>
                            </div>
                        </div>

                        <TransactionSummary :summary="summary"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Currency from '@/Components/Common/Currency.vue'
import Percent from '@/Components/Common/Percent.vue'
import TransactionSummary from '@/Components/Dashboard/TransactionSummary.vue'

export default {
    props: [
        'summary',
        'product',
        'brandIsSelected',
    ],
    components: {
        TransactionSummary,
        Percent,
        Currency
    },
    data() {
        return {
            notification: {
                show: false,
                message: 'Успешно скопировано'
            },
        };
    },
    computed: {
        balanceLabel() {
            return `Полное количество: ${this.product.quantityFull}
            <br>Доступно для продажи: ${this.product.quantity}
            <br>В пути к клиенту: ${this.product.inWayToClient}
            <br>В пути от клиента: ${this.product.inWayFromClient}`;
        },
    },
}
</script>
