<template>
    <span class="currency">{{formattedValue}}</span>
</template>

<script>
import numeral from 'numeral'
import { store } from '@/store'

export default {
    name: 'Currency',
    props: {
        value: {
            required: true
        },
    },
    data() {
        return {
            store
        }
    },
    computed: {
        formattedValue() {
            if (typeof this.value !== "undefined") {
                numeral.locale(`cur_${this.store.currency}`);

                return numeral(this.value).format('0,0.00 $');
            }

            return '';
        }
    },
}
</script>

<style scoped>

</style>
