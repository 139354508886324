<template>
    <button
        class="product-more-btn fm-chevron-right"
        aria-label="Больше"
        @click.prevent="showModal = true"
    />
    <Modal
        ref="warehouseProductDetailsModal"
        v-if="showModal"
        @close="showModal = false"
    >
        <template #title>
            <h5 class="modal-title">Товар</h5>
        </template>
        <template #body>
            <div class="info-list">
                <div class="info-list__header">
                    <img class="info-list__header__img" :src="product.image" width="40" height="40" alt="title"
                         loading="lazy">
                    <div class="info-list__header__body">
                        <div class="info-list__header__title">{{ product.title }}</div>
                        <div>
                                  <span class="item__product-id">
                                      <CopyButton :text-to-copy="String(product.nm_id)"/>
                                      <a v-if="product.link" class="item__link" :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                      <span v-else>{{ product.nm_id }}</span>
                                  </span><span> / {{ product.barcode }}</span>
                        </div>
                        <div>Цена:
                            <Currency :value="product.discounted_price"/>
                        </div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">Кол-во на складе</div>
                        <div class="info-list__input__text-input">{{ product.quantity }}</div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">Кол-во в резерве</div>
                        <div class="info-list__input__text-input">{{ product.quantity_reserved }}</div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">Себестоимость склада</div>
                        <div class="info-list__input__text-input">
                            <Currency :value="product.warehouse_cost"/>
                        </div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">Скорость продаж (ед. в день)</div>
                        <div class="info-list__input__text-input">{{ product.sales_speed }}</div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">Остаток на складе в днях</div>
                        <div class="info-list__input__text-input">
                            <span :class="quantityDaysClasses">{{ product.quantity_days }}</span>
                        </div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">Дней до следующего заказа</div>
                        <div class="info-list__input__text-input">
                            <span :class="nextOrderDaysClasses">{{ product.next_order_days }}</span>
                        </div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">Рекомендуемое кол-во для дозаказа</div>
                        <div class="info-list__input__text-input">{{ product.recommend_to_order }}</div>
                    </div>
                </div>

                <div class="info-list__input info-list__input_dasibled">
                    <div class="info-list__input__inner">
                        <div class="info-list__input__label">ROI</div>
                        <div class="info-list__input__text-input">
                            <Percent :value="product.roi"/>
                        </div>
                    </div>
                </div>


                <div class="info-list__input">
                    <div class="info-list__input__inner" v-if="type == 'group'">
                        <div class="info-list__input__label">Кол-во на промежуточном складе</div>
                        <div class="info-list__input__text-input">{сумма значений}</div>
                    </div>

                    <div class="info-list__input__inner" v-else>
                        <label class="info-list__input__label" for="cp-1">Кол-во на промежуточном складе</label>
                        <WarehouseParamInput
                            ref="intermediateWarehouse"
                            option-key="intermediate_warehouse"
                            css-class="info-list__input__text-input"
                            :product="product"
                            :auto-save="false"
                            @notification="$emit('notification',$event)"
                            @param-updated="paramWasUpdated"
                        />
                    </div>
                </div>

                <div class="info-list__input">
                    <div class="info-list__input__inner" v-if="type == 'group'">
                        <div class="info-list__input__label">Объем закупки</div>
                        <div class="info-list__input__text-input">{сумма значений}</div>
                    </div>

                    <div class="info-list__input__inner" v-else>
                        <label class="info-list__input__label" for="cp-2">Объем закупки</label>
                        <WarehouseParamInput
                            ref="purchase"
                            option-key="purchase"
                            css-class="info-list__input__text-input"
                            :product="product"
                            :auto-save="false"
                            @notification="$emit('notification',$event)"
                            @param-updated="paramWasUpdated"
                        />
                    </div>
                </div>

                <div class="info-list__input">
                    <div class="info-list__input__inner" v-if="type == 'child'">
                        <div class="info-list__input__label">Срок выполнения заказа</div>
                        <div class="info-list__input__text-input">{{ product.order_days }}</div>
                    </div>

                    <div class="info-list__input__inner" v-else>
                        <label class="info-list__input__label" for="cp-3">Срок выполнения заказа</label>
                        <WarehouseParamInput
                            ref="orderDays"
                            option-key="order_days"
                            css-class="info-list__input__text-input"
                            :product="product"
                            :auto-save="false"
                            @notification="$emit('notification',$event)"
                            @param-updated="paramWasUpdated"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #buttons>
            <button class="btn-default btn-cancel" type="button" @click="closeModal">Отменить</button>
            <button
                type="submit"
                class="btn-default btn-regular"
                :disabled="!canSubmit"
                @click.prevent="saveData"
            >Сохранить</button>
        </template>
    </Modal>
</template>
<script>
import CopyButton from '@/Components/Common/CopyButton.vue'
import Currency from '@/Components/Common/Currency.vue'
import Percent from '@/Components/Common/Percent.vue'
import WarehouseParamInput from '@/Components/Warehouse/WarehouseParamInput.vue'
import Modal from '@/Components/Common/Modal.vue'

export default {
    name: 'ProductDetailsModal',
    components: { Modal, WarehouseParamInput, Percent, Currency, CopyButton },
    props: {
        product: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: ''
        }
    },
    data () {
        return {
            showModal: false,
            canSubmit: false,
        }
    },
    emits: ['product-updated', 'notification'],
    computed: {
        quantityDaysClasses() {
            return {
                'status-tag': true,
                'status-tag_danger': (this.product.quantity_days > 0 && this.product.quantity_days <= 7) || (this.product.sales_speed > 0 && this.product.quantity_days === 0),
                'status-tag_partial': this.product.quantity_days > 7 && this.product.quantity_days <= 14,
            }
        },
        nextOrderDaysClasses() {
            return {
                'status-tag': true,
                'status-tag_danger': this.product.next_order_days <= 0 && this.product.sales_speed > 0,
                'status-tag_partial': (this.product.next_order_days > 0 && this.product.next_order_days <= 7) && this.product.sales_speed > 0,
            }
        },
    },
    methods: {
        closeModal () {
            this.$refs.warehouseProductDetailsModal.close();
        },
        paramWasUpdated () {
            this.canSubmit = this.$refs.intermediateWarehouse.wasChanged()
                            || this.$refs.purchase.wasChanged()
                            || this.$refs.orderDays.wasChanged();
        },
        saveData () {
            Promise.all([
                this.$refs.intermediateWarehouse.saveValue().catch(() => {}),
                this.$refs.purchase.saveValue().catch(() => {}),
                this.$refs.orderDays.saveValue().catch(() => {}),
            ]).then(() => {
                this.$emit('product-updated');
                this.closeModal();
            });
        }
    }
}
</script>
