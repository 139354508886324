<template>
    <div class="dash__board__container">
        <div class="main_container">
            <BreezeDashboardInfo></BreezeDashboardInfo>

            <BrokenApiKey v-if="isKeyBroken"/>
            <WildBerriesTechPause v-if="isTechPause && isSupported"/>

<!--            <WildBerriesDataNotReady v-if="isDataNotReady"/>-->
            <div class="dashboard_panel dashboard_panel_dashboard">
                <div class="dashboard-nav">
                    <ul class="dashboard-nav__list">

                        <li v-for="(item, index) in dataByPeriods" class="dashboard-nav__item">
                            <button :class="{active: currentRange && currentRange.id === item.period.id}"
                                    class="dashboard-nav__link" type="button"
                                    @click="goToSlide(index)">{{ item.period.title }}
                            </button>
                        </li>

                    </ul>

                    <div class="dashboard-nav__btn-container">
                        <div class="dashboard-nav__search">
                            <!-- <DashboardProductFilter v-bind:products="products" @changed="selectedProductsChanged"></DashboardProductFilter> -->
                            <div class="search_outer desktop_select">
                                <ProductsSelector
                                    ref="ProductsSelector2"
                                    style="margin-bottom:0;"
                                    v-bind:page="query.pageSearch"
                                    v-bind:products="productsSelected"
                                    v-bind:selectAll="query.selectAll"
                                    @changed="selectedProductsChanged"
                                    @selectAllChanged="selectAllChanged"
                                    @submit="filterClicked"></ProductsSelector>
                            </div>

                            <div class="top_filter_row__column" v-if="isSupported">
                                <div class="dropdown">
                                    <button aria-expanded="false" aria-haspopup="true" class="index_select_button"
                                            data-toggle="dropdown" type="button">

                                        <span v-if="!queryCache.countries">Все страны</span>
                                        <span v-if="queryCache.countries">Страны: {{ cacheCountries.length }}</span>
                                        <span class="fm-chevron-down"></span>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu_form">
                                        <form>
                                            <div v-for="(item,key) in select2Countries" class="input">
                                                <div class="input__inner">
                                                    <input :id="'c-'+key" :checked="cacheCountries.includes(item.text)"
                                                           class="input__checkbox"
                                                           type="checkbox" @change="addToSelectedCountries(item.id)">
                                                    <label :for="'c-'+key"
                                                           class="input__checkbox-label">{{ item.text }}</label>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                            <div class="filter_button top_filter_row__column">
                                <button :class="queryModified ? 'active' : ''" :disabled="!queryModified"
                                        class="index_filter_button"
                                        data-element=".filter_button" data-toggle="false"
                                        type="button" v-on:click="filterClicked"><span
                                    class="fm-filter"></span> Фильтр
                                </button>
                            </div>

                            <div v-if="queryCache.countries || query.products || query.range != 1"
                                 class="dashboard-nav__clear-filter-button">
                                <button class="btn-default" type="button" @click="filterClear"><span
                                    class="fm-x"></span>Сбросить
                                </button>
                            </div>


                        </div>
                        <button :class="{active: query.countries || query.products || currentRangeSet.id !== 1}"
                                class="dashboard-nav__btn dashboard-nav__btn_mob"
                                data-target="#filterModal"
                                data-toggle="modal" type="button">
                            <i class="fm-filter"></i>
                        </button>

                        <button :class="{active: currentRangeSet.id !== 1}"
                                class="dashboard-nav__btn dashboard-nav__btn_desktop" data-target="#filterRangesModal"
                                data-toggle="modal"
                                type="button">
                            <i class="fm-calendar"></i>
                        </button>
                    </div>
                </div>

                <TableLoader :show="loadingPeriods"/>

                <div class="panels-carousel-wrapper">
                    <swiper-container class="panels-carousel" init="false">
                        <swiper-slide v-for="(item, index) in dataByPeriods">
                            <SummaryPeriodItem
                                :key="`period_${index}`"
                                :currentMonthRange="currentMonthRange"
                                :currentRange="currentRange"
                                :ignoreLoadingOrders="forAll"
                                :index="index"
                                :item="item"
                                :loadingPeriods="loadingPeriods"
                                @changed="currentRangeChanged"
                            />
                        </swiper-slide>
                    </swiper-container>
                </div>
            </div>

            <div v-if="!loadingPeriods" class="table_outer">
                <div class="table-tabs">
                    <ul id="pills-tab" class="nav nav-pills" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                id="pills-products-tab"
                                :class="{active: section === 'products'}"
                                aria-controls="pills-products"
                                aria-selected="true"
                                class="nav-link"
                                data-target="#pills-products"
                                data-toggle="pill"
                                role="tab"
                                type="button"
                                @click="changeSection('products')">
                                <svg class="table-tabs__ico" fill="none" height="20" stroke="currentColor"
                                     stroke-linecap="round"
                                     stroke-linejoin="round" stroke-width="1.2" viewBox="0 0 20 20"
                                     width="20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.333 3.33301H14.9997C15.4417 3.33301 15.8656 3.5086 16.1782 3.82116C16.4907 4.13372 16.6663 4.55765 16.6663 4.99967V16.6663C16.6663 17.1084 16.4907 17.5323 16.1782 17.8449C15.8656 18.1574 15.4417 18.333 14.9997 18.333H4.99967C4.55765 18.333 4.13372 18.1574 3.82116 17.8449C3.5086 17.5323 3.33301 17.1084 3.33301 16.6663V4.99967C3.33301 4.55765 3.5086 4.13372 3.82116 3.82116C4.13372 3.5086 4.55765 3.33301 4.99967 3.33301H6.66634"/>
                                    <path
                                        d="M12.5003 1.66699H7.50033C7.04009 1.66699 6.66699 2.04009 6.66699 2.50033V4.16699C6.66699 4.62723 7.04009 5.00033 7.50033 5.00033H12.5003C12.9606 5.00033 13.3337 4.62723 13.3337 4.16699V2.50033C13.3337 2.04009 12.9606 1.66699 12.5003 1.66699Z"/>
                                </svg>
                                <span class="nav-link__text">Продажи</span>
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                id="pills-orders-tab"
                                :class="{active: section === 'orders'}"
                                aria-controls="pills-orders"
                                aria-selected="false"
                                class="nav-link"
                                data-target="#pills-orders"
                                data-toggle="pill"
                                role="tab"
                                type="button"
                                @click="changeSection('orders')">
                                <svg class="table-tabs__ico" fill="none" height="20" viewBox="0 0 20 20" width="20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.40527 17.7773C8.09563 17.7773 8.65527 17.2177 8.65527 16.5273C8.65527 15.837 8.09563 15.2773 7.40527 15.2773C6.71492 15.2773 6.15527 15.837 6.15527 16.5273C6.15527 17.2177 6.71492 17.7773 7.40527 17.7773Z"
                                        fill="currentColor"/>
                                    <path
                                        d="M15 17.7773C15.6904 17.7773 16.25 17.2177 16.25 16.5273C16.25 15.837 15.6904 15.2773 15 15.2773C14.3096 15.2773 13.75 15.837 13.75 16.5273C13.75 17.2177 14.3096 17.7773 15 17.7773Z"
                                        fill="currentColor"/>
                                    <path
                                        d="M18.3774 2.98305C18.3257 2.91933 18.2605 2.86784 18.1866 2.8323C18.1126 2.79676 18.0317 2.77804 17.9496 2.7775H6.38295L6.74406 3.88861H17.2218L15.7385 10.5553H7.40517L4.86628 2.51639C4.83882 2.43109 4.79113 2.3537 4.72728 2.29082C4.66343 2.22794 4.58532 2.18143 4.49961 2.15528L2.22184 1.45528C2.1518 1.43375 2.07821 1.42624 2.00526 1.43316C1.93232 1.44008 1.86145 1.46129 1.79671 1.4956C1.66596 1.56488 1.56808 1.68327 1.52461 1.82472C1.48115 1.96617 1.49565 2.11909 1.56494 2.24985C1.63422 2.3806 1.75261 2.47848 1.89406 2.52194L3.8885 3.13305L6.4385 11.1886L5.52739 11.9331L5.45517 12.0053C5.2298 12.265 5.10206 12.5952 5.09395 12.9389C5.08583 13.2827 5.19786 13.6186 5.41072 13.8886C5.56215 14.0728 5.75456 14.2189 5.97258 14.3154C6.1906 14.4118 6.42816 14.4559 6.66628 14.4442H15.9385C16.0858 14.4442 16.2271 14.3856 16.3313 14.2814C16.4355 14.1773 16.4941 14.036 16.4941 13.8886C16.4941 13.7413 16.4355 13.6 16.3313 13.4958C16.2271 13.3916 16.0858 13.3331 15.9385 13.3331H6.57739C6.51342 13.3309 6.45109 13.3122 6.39642 13.2789C6.34176 13.2456 6.29661 13.1988 6.26533 13.1429C6.23406 13.0871 6.21771 13.0241 6.21788 12.9601C6.21804 12.8961 6.23471 12.8332 6.26628 12.7775L7.60517 11.6664H16.1829C16.3114 11.6695 16.4369 11.6281 16.5382 11.5491C16.6395 11.47 16.7103 11.3584 16.7385 11.2331L18.4996 3.45528C18.5165 3.37245 18.5143 3.28687 18.4931 3.20503C18.4719 3.1232 18.4323 3.04728 18.3774 2.98305Z"
                                        fill="currentColor"/>
                                </svg>
                                <span class="nav-link__text">Заказы</span>
                            </button>
                        </li>
                        <li class="table-tabs__actions">
                            <button :class="{disabled: isDownloading}" class="download_button btn-link"
                                    @click.prevent="downloadReport()">
                                <span class="btn-link__ico fm-download" v-if="!isDownloading"></span>
                                <span class="spinner-border spinner-border-sm" v-else role="status">
                                     <span class="visually-hidden">Loading...</span>
                                </span>
                                <span class="btn-link__text">Скачать таблицу (.xls)</span>
                                <span v-show="isDownloading" class="btn-loader">
                            </span>
                            </button>
                            <div v-if="!importOff">
                                <button v-if="!accountInfo.is_updating && isAvailable('business')"
                                        class="btn-link"
                                        data-target="#reportsImportModal" data-toggle="modal" type="button">
                                    <span class="btn-link__ico fm-upload"></span>
                                    <span class="btn-link__text">Импорт отчетов</span>
                                </button>
                            </div>

                            <!-- <div class="input">
                                <div class="input__inner">
                                    <input class="input__checkbox" id="group-by-sku" v-model="groupByArticul" type="checkbox" :disabled="!paginationCustomParams.has_group_by_nm_id || loadingProducts">
                                    <label class="input__checkbox-label" for="group-by-sku">Сгруппировать</label>
                                </div>
                            </div> -->
                            <div v-show="section === 'products'" class="group-options-wrapper">
                                <span v-show="!(groupBySku === 'noGrouping' || groupBySku === '')"
                                      class="group-options-title">Группировать по</span>
                                <div id="group-by-sku"
                                     :disabled="!paginationCustomParams.has_group_by_nm_id || loadingProducts"
                                     class="group-options">
                                    <Select2
                                        v-model="groupBySku"
                                        :options="salesGroupOptions"
                                        :settings="{ theme: 'sd_select_narrow', minimumResultsForSearch: -1, prompt: 'bySku', placeholder: 'Группировать по', allowClear: false, width: '100%', dropdownParent: '#group-by-sku', templateSelection: formatOrderingState}"
                                        @select="changeSalesOrdering"
                                    />
                                </div>
                            </div>

                            <div v-show="section === 'orders'" class="group-options-wrapper">
                                <span v-show="!(groupOrdersBy === 'noGrouping')" class="group-options-title">Группировать по</span>
                                <div id="groupOptions" class="group-options">
                                    <Select2
                                        v-model="groupOrdersBy"
                                        :options="ordersGroupOptions"
                                        :settings="{ theme: 'sd_select_narrow', minimumResultsForSearch: -1, prompt: 'byOrders', placeholder: 'Группировать по', allowClear: false, width: '100%', dropdownParent: '#groupOptions', templateSelection: formatOrderingState }"
                                        @select="changeOrdering"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div id="pills-tabContent" class="tab-content">
                        <div id="pills-products" :class="{'show active': section === 'products'}" class="tab-pane fade">
                            <DashboardProductsTable
                                v-if="section === 'products'"
                                :key="componentKeyProducts"
                                ref="DashboardProductsTable"
                                :external-data-loading="externalDataLoading"
                                :forAll="forAll"
                                v-bind:currentRange="currentRange"
                                v-bind:forAll="forAll"
                                v-bind:query="query"
                                v-bind:section="section"
                                @dashboardCostEdit="costEdit"
                                @loadingProducts="setProductsLoading"
                                @paginationCustomParams="setPaginationCustomParams"
                                @queryChanged="queryChanged"
                                @reload="reloadTable"
                                @setSummary="setSummary"
                            />
                        </div>
                        <div id="pills-orders" :class="{'show active': section === 'orders'}" class="tab-pane fade">
                            <DashboardOrdersTable
                                v-if="section === 'orders'"
                                :key="componentKeyOrders"
                                ref="DashboardOrdersTable"
                                v-bind:currentMonthRange="currentMonthRange"
                                v-bind:currentRange="currentRange"
                                v-bind:forAll="forAll"
                                v-bind:groupByArticul="groupByArticul"
                                v-bind:groupOrdersBy="groupOrdersBy"
                                v-bind:query="query"
                                v-bind:section="section"
                                @queryChanged="queryChanged"
                                @reload="reloadTable"
                                @setSummary="setSummary"

                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <ProductsCostModal ref="ProductsCostModal" @reload="reloadTable"></ProductsCostModal>
    <DashboardFilterModal ref="FilterModal"
                          v-bind:currentRangeSet="currentRangeSet"
                          v-bind:dateFirstEvent="dateFirstEvent"
                          v-bind:dateFrom="dateFrom"
                          v-bind:dateRangeSets="dateRangeSets"
                          v-bind:dateTo="dateTo"
                          v-bind:filterCountries="filterCountries"
                          v-bind:productsSelected="productsSelected"
                          v-bind:query="query"
                          v-bind:selectedCountries="cacheCountries"
                          @apply="filterClicked"
                          @countriesChanged="countriesChangedMobile"
                          @datesChanged="datesChanged"
                          @filterClear="filterClear"
                          @rangeSetChanged="rangeSetChanged"
                          @selectedProductsChanged="selectedProductsChanged"
    />
    <DashboardFilterRangesModal ref="FilterModal"
                                v-bind:currentRangeSet="currentRangeSet"
                                v-bind:dateFirstEvent="dateFirstEvent"
                                v-bind:dateFrom="dateFrom"
                                v-bind:dateRangeSets="dateRangeSets"
                                v-bind:dateTo="dateTo"
                                v-bind:productsSelected="productsSelected"
                                v-bind:query="query"
                                @apply="filterClicked"
                                @datesChanged="datesChanged"
                                @rangeSetChanged="rangeSetChanged"
                                @selectedProductsChanged="selectedProductsChanged"
    />

    <DashboardSummaryModal v-bind:brandIsSelected="brandIsSelected" v-bind:product="summaryProduct"
                           v-bind:summary="summary"/>
    <div id="notificationMessage" class="notification-message">
        <span>Успешно скопирован</span>
    </div>
    <transition name="fade">
        <div v-if="notification.show" id="notificationMessage"
             :class="notification.type" class="notification-message is-display-block notification-message_offset">
            <span>{{ notification.message }}</span>
        </div>
    </transition>

    <Teleport v-if="!this.importOff" to="body">
        <div id="reportsImportModal" aria-hidden="true" aria-labelledby="reportsImportModalLabel"
             class="modal modal-fullscreen reports-import-modal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-label="Back" class="modal-back" data-dismiss="modal" type="button">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 id="reportsImportModalLabel" class="h4 modal-title">Импорт отчетов</h5>
                        <button aria-label="Close" class="modal-close" data-dismiss="modal" type="button">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="reports-import-modal__help-panel">
                            <div>
                                <h5 class="reports-import-modal__help-panel__title">Как импортировать еженедельные
                                    отчеты?</h5>
                                <!--
                                                                <p>Aliquam aliquam vitae ut sit morbi lacus.</p>
                                -->
                            </div>
                            <a aria-label="Читать инструкцию" class="reports-import-modal__help-panel__link"
                               href="/dashboard/import" target="_blank">
                                <span class="fm-arrow-right"></span>
                            </a>
                        </div>

                        <DropFileInput
                            :inputLabel="'Нажмите сюда и выберите файлы с отчетами или перетащите их в эту область'"
                            v-bind:fileErrors="fileErrors"
                            v-bind:fileLoaded="fileLoaded"
                            @upload='fileUpload'>
                        </DropFileInput>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>

</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import BreezeDashboardInfo from '@/Components/Dashboard/DashboardInfo.vue'
import DashboardVideoModal from '@/Components/Dashboard/DashboardVideoModal.vue'
import DashboardFilterModal from '@/Components/Dashboard/DashboardFilterModal.vue'
import DashboardFilterRangesModal from '@/Components/Dashboard/DashboardFilterRangesModal.vue'
import DashboardDateRanges from '@/Components/Dashboard/DashboardDateRanges.vue'
import SummaryPeriodItem from '@/Components/Dashboard/SummaryPeriodItem.vue'
import ProductsCostModal from '@/Components/Products/ProductsCostModal.vue'
import { Head } from '@inertiajs/inertia-vue3'
import DashboardProductsTable from '@/Components/Dashboard/DashboardProductsTable'
import DashboardOrdersTable from '@/Components/Dashboard/DashboardOrdersTable'
import DashboardGroupedProductsTable from '@/Components/Dashboard/DashboardGroupedProductsTable'
import DashboardProductFilter from '@/Components/Dashboard/DashboardProductFilter'
import ProductsSelector from '@/Components/Products/ProductsSelector'
import { Inertia } from '@inertiajs/inertia'
import { pickBy, throttle } from 'lodash-es'
import DashboardSummaryModal from '@/Components/Dashboard/DashboardSummaryModal'
import Select2 from 'vue3-select2-component'
import BrokenApiKey from '@/Components/Common/BrokenApiKey'
import WildBerriesTechPause from '@/Components/Common/WildBerriesTechPause.vue'
import WildBerriesDataNotReady from '@/Components/Common/WildBerriesDataNotReady'
import moment from 'moment-timezone'
import TransactionSummary from '@/Components/Dashboard/TransactionSummary.vue'
import DropFileInput from '@/Components/Common/DropFileInput.vue'
import { store } from '@/store'
import TableLoader from '@/Components/Common/TableLoader.vue'

export default {
    components: {
        TableLoader,
        WildBerriesTechPause,
        WildBerriesDataNotReady,
        DashboardProductFilter,
        DashboardProductsTable,
        DashboardOrdersTable,
        DashboardGroupedProductsTable,
        BreezeAuthenticatedLayout,
        BreezeDashboardInfo,
        DashboardVideoModal,
        DashboardFilterModal,
        DashboardFilterRangesModal,
        DashboardDateRanges,
        ProductsCostModal,
        ProductsSelector,
        DashboardSummaryModal,
        Head,
        Select2,
        BrokenApiKey,
        SummaryPeriodItem,
        TransactionSummary,
        DropFileInput
    },
    props: [
        'currentMonthRange',
        'currentRangeSet',
        'dateRangeSets',
        'products',
        'dateFrom',
        'dateFirstEvent',
        'dateTo',
        'filterCountries',
        'externalDataLoading',
        'forAll',
        'hideExport',
        'importOff'
    ],
    data() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let slider;

        const clientFilterData = localStorage.getItem(`dashboard/filter`.toUpperCase());
        let dataToWrite = {};
        let cacheFilter = false;

        if (clientFilterData) {
            dataToWrite = JSON.parse(clientFilterData);
            cacheFilter = true;
            this.currentRange = {
                id: dataToWrite.period,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            }
        } else {
            dataToWrite = this.currentRange = this.currentRangeSet.currentRange;
        }

        const query = {
            selectAll: false,
            pageSearch: 0,
            range: cacheFilter ? dataToWrite.range : this.currentRangeSet.id,
            period: cacheFilter ? dataToWrite.period : this.currentRange.id,
            products: cacheFilter ? (Array.isArray(dataToWrite.products) ? dataToWrite.products.join(',') : dataToWrite.products) : params.get('products'),
            orderBy: params.get('orderBy') ?? 'quantity',
            desc: params.get('desc') ?? 1,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            groupBy: params.get('groupBy') ?? 'nm_id',
            timestamp: 0,
            tab: '',
            forAll: this.forAll ?? false,
            //countries: params.get('countries') ?? this.filterCountries.map(c => c.id).join(','),
            countries: cacheFilter ? dataToWrite.countries : params.get('countries'),
        }

        const queryCache = Object.assign({}, query);

        return {
            isDownloading: false,
            currentRange: this.currentRange,
            query: query,
            queryCache: queryCache,
            queryModified: false,
            loading: false,
            loadingPeriods: true,
            loadingProducts: false,
            componentKey: 0,
            componentKeyProducts: 0,
            componentKeyOrders: 0,
            groupByArticul: true,
            groupBySku: 'bySku',// 'bySku', 'noGrouping'
            groupOrdersBy: 'byOrders',
            groupOrdersByDefault: 'byOrders',
            groupingIsDefault: true, // products - bySku, orders - byOrders
            productsSelected: [],
            paginationCustomParams: [],
            summary: {},
            summaryProduct: {},
            brandIsSelected: false,
            section: 'products',
            accountInfo: {
                orders_updating: 0,
                orders_updating_first_month: 0
            },
            dataByPeriods: [],
            select2Countries: [],
            salesGroupOptions: [{id: 'noGrouping', text: 'Не группировать'}, {
                id: 'bySku',
                text: 'Артикулу'
            }, {id: 'brand', text: 'Бренду'}],
            ordersGroupOptions: [{id: 'noGrouping', text: 'Не группировать'}, {
                id: 'byOrders',
                text: 'Заказам'
            }, {id: 'bySku', text: 'Артикулу'}],
            initialScreenWidth: window.innerWidth,
            slider,
            reportLoaded: true,
            headerTab: '',
            exportLink: null,
            fileErrors: [],
            fileLoaded: [],
            notification: {
                show: false,
                message: 'Успешно скопировано',
                type: 'info'
            },
            cacheCountries: [],
            store,
        };
    },
    computed: {
        isKeyBroken: function () {
            return !this.store.account.status;
        },
        isSupported: function () {
            return this.store.account.data_source === 'wildberries';
        },
        isTechPause: function () {
            return !this.isKeyBroken && this.techPauseTime;
        },
        isDataNotReady: function () {
            return !this.loadingPeriods && !this.isTechPause && !this.loading && !this.reportLoaded;
        },
        techPauseTime: function () {
            const now = moment().tz('Europe/Moscow');

            return now.day() === 1 && now.hour() > 0 && now.hour() < 16;
        },
        filterClientDataNS() {
            if (this.forAll) {
                return `account-${this.$page.props.auth.user.user_account.account_id}/Dashboard/General/filter`.toUpperCase();
            }
            return `account-${this.$page.props.auth.user.user_account.account_id}/Dashboard/filter`.toUpperCase();
        },
        isDataStable() {
            if (this.store.stableDataEndDate === null) {
                return true;
            }

            const lastSunday = moment().tz('Europe/Moscow').subtract(7, 'days').endOf('week');

            return lastSunday.isSame(moment(store.stableDataEndDate).endOf('week'), 'week');
        },
        stableDataEndDate() {
            return moment(store.stableDataEndDate).format('DD.MM.YYYY');
        },
    },
    created() {
        const clientFilterData = localStorage.getItem(this.filterClientDataNS);
        if (clientFilterData) {
            const filterData = JSON.parse(clientFilterData);

            this.currentRange = {
                id: filterData.period,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            }

            this.query = filterData;
            this.queryCache = filterData;

            if (filterData.countries) {
                this.cacheCountries = filterData.countries.split(',');
            }

            if (filterData.groupBy === 'nm_id') {
                this.groupBySku = 'bySku';
                this.groupByArticul = true;
            } else if (filterData.groupBy === 'brand') {
                this.groupBySku = 'brand';
            } else {
                this.groupBySku = '';
                this.groupByArticul = false;
            }
        }
    },
    methods: {
        setAccountInfo(accountInfo) {
            this.accountInfo = accountInfo;

        },
        isAvailable: function (packageType) {
            return this.store.subscription.trial || store.subscription.type === packageType;
        },
        setSummary(summary, summaryProduct, isBrandSelected = false) {
            console.log('3', isBrandSelected);
            this.brandIsSelected = isBrandSelected;
            this.summary = summary;
            if (summaryProduct)
                this.summaryProduct = summaryProduct;
            else
                this.summaryProduct = {};
            $('#summaryModal').modal();
        },
        queryChanged(newQuery) {
            this.queryCache = newQuery;
            //this.reloadTable(newQuery);
        },
        addToSelectedCountries(id) {
            if (this.cacheCountries.includes(id)) {
                let index = this.cacheCountries.indexOf(id);
                if (index !== -1) {
                    this.cacheCountries.splice(index, 1);
                }
            } else {
                this.cacheCountries.push(id);
            }
            this.queryCache.countries = this.cacheCountries.join(',');
            this.checkQueryStatus();
        },
        filterClear() {
            this.queryCache.products = [];
            this.queryCache.countries = '';
            this.queryCache.range = 1;
            this.queryCache.selectAll = false;
            this.queryCache.pageSearch = 0;
            this.queryCache.period = 'current_month';

            this.filterClicked();
        },
        changeSection(section) {
            if (this.section !== section && !this.groupingIsDefault) {
                if (section === 'products') {
                    if (this.groupOrdersBy === 'bySku') {
                        this.groupBySku = 'bySku';
                        this.groupByArticul = true;
                        this.query.groupBy = 'nm_id';
                    } else if (this.groupOrdersBy === 'noGrouping') {
                        this.groupBySku = 'noGrouping';
                        this.groupByArticul = false;
                    } else {
                        this.groupBySku = 'noGrouping';
                        this.groupByArticul = false;
                    }
                } else if (section === 'orders') {
                    if (this.groupBySku === 'bySku') {
                        this.groupOrdersBy = 'bySku';
                        this.query.groupBy = 'bySku';
                    } else if (this.groupBySku === 'noGrouping' || this.groupBySku === '') {
                        this.groupOrdersBy = 'noGrouping';
                    } else {
                        this.groupOrdersBy = 'byOrders';
                    }
                }
            }
            this.section = section;
        },
        countryFilter() {
            let countryToString = Array.isArray(this.cacheCountries) ? this.cacheCountries.join(',') : this.cacheCountries;
            this.query.countries = countryToString;
            this.queryCache.countries = countryToString;
            this.filterClicked();
        },
        setProductsLoading(loading) {
            this.loadingProducts = loading;
            console.log('loadingProducts:' + loading);
        },
        setPaginationCustomParams(params) {
            this.paginationCustomParams = params;
        },
        costEdit(id) {
            this.$refs.ProductsCostModal.costEdit(id);
        },
        /*loadingData(value) {
            this.externalDataLoading = value;
        },*/
        reloadTable(data) {
            if (data && data.page)
                this.query.page = data.page;
            if (this.section === 'products') {
                this.componentKeyProducts += 1;
                if (data && data.fullReload) {
                    this.getDataByPeriods();
                }
            } else if (this.section === 'orders') {
                this.componentKeyOrders += 1;
            }
        },
        getDataByPeriods(withLoading = true) {
            this.loadingPeriods = withLoading;
            this.queryCache.products = Array.isArray(this.queryCache.products) ? this.queryCache.products.join(',') : this.queryCache.products;
            this.queryCache.countries = Array.isArray(this.queryCache.countries) ? this.queryCache.countries.join(',') : this.queryCache.countries
            this.queryCache.dateFrom = this.dateFrom;
            this.queryCache.dateTo = this.dateTo;

            let url = this.route('dashboardDataByPeriods');

            if (this.forAll) {
                url = this.route('dashboardDataByPeriodsForAll');
            }

            axios.get(url, {params: pickBy(this.queryCache)}).then((response) => {
                this.dataByPeriods = response.data;
                this.loadingPeriods = false;
            })
        },
        currentRangeChanged(range) {
            this.currentRange = range;
            this.query.period = range.id;
            this.query.page = 1;
            this.query.dateFrom = moment(range.dateFrom).format('YYYY-MM-DD');
            this.query.dateTo = moment(range.dateTo).format('YYYY-MM-DD');

            this.queryCache.range = this.query.range;
            this.queryCache.period = this.query.period;
            this.reloadTable();
        },
        rangeSetChanged(rangeSet) {
            this.queryCache.range = this.query.range = rangeSet.id;
            this.queryCache.period = this.query.period = rangeSet.default.id
            this.checkQueryStatus();
            //this.filterClicked();
        },
        datesChanged(dates) {
            this.queryCache.range = this.query.range = 7;
            this.queryCache.period = this.query.period = 'custom';
            this.queryCache.dateFrom = this.query.dateFrom = dates.dateFrom;
            this.queryCache.dateTo = this.query.dateTo = dates.dateTo;
            //this.filterClicked();
        },
        countriesChanged(countries) {
            this.query.countries = countries;
            this.queryCache.countries = countries;
            this.checkQueryStatus()
            //this.filterClicked();
        },
        countriesChangedMobile(countries) {
            this.queryCache.countries = Array.isArray(countries) ? countries.join(',') : countries
        },
        arrayEquals(a, b) {
            if (Array.isArray(b) && !b.length && !a)
                return true;

            return a === b || (Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val == b[index]));
        },

        checkQueryStatus() {
            if (Array.isArray(this.queryCache.products) && this.queryCache.products.join() === this.query.products && this.query.range === this.queryCache.range) {
                this.queryModified = false;
            } else {
                //this.queryModified = this.query.range !== this.queryCache.range || !this.arrayEquals(this.query.products, this.queryCache.products);
                this.queryModified = true
            }
        },

        filterClicked() {
            let productsQuery = '';
            let countriesQuery = '';
            let tab = '&tab=grid';

            if (this.queryCache.products) {
                productsQuery = '&products=' + ((Array.isArray(this.queryCache.products)) ? this.queryCache.products.join(',') : this.queryCache.products);
            }

            if (this.queryCache.countries) {
                countriesQuery = '&countries=' + ((Array.isArray(this.queryCache.countries)) ? this.queryCache.countries.join(',') : this.queryCache.countries);
            }

            let query = Object.assign({}, this.query);

            if (this.queryCache.products) {
                query.products = ((Array.isArray(this.queryCache.products)) ? this.queryCache.products.join(',') : this.queryCache.products);
            }
            if (this.queryCache.countries) {
                query.countries = ((Array.isArray(this.queryCache.countries)) ? this.queryCache.countries.join(',') : this.queryCache.countries);
            }

            this.query.range = this.queryCache.range;
            this.query.period = this.queryCache.period;

            if (this.queryCache.range == 7) {
                this.query.dateFrom = this.queryCache.dateFrom;
                this.query.dateTo = this.queryCache.dateTo;
                if (this.forAll) {
                    Inertia.visit('/dashboard/general?range=' + this.queryCache.range + '&period=' + this.queryCache.period + productsQuery + countriesQuery + tab + '&dateFrom=' + this.queryCache.dateFrom + '&dateTo=' + this.queryCache.dateTo);
                } else {
                    Inertia.visit('/dashboard?range=' + this.queryCache.range + '&period=' + this.queryCache.period + productsQuery + countriesQuery + tab + '&dateFrom=' + this.queryCache.dateFrom + '&dateTo=' + this.queryCache.dateTo);
                }
            } else {
                if (this.forAll) {
                    Inertia.visit('/dashboard/general?range=' + this.queryCache.range + '&period=' + this.queryCache.period + productsQuery + countriesQuery + tab);
                } else {
                    Inertia.visit('/dashboard?range=' + this.queryCache.range + '&period=' + this.queryCache.period + productsQuery + countriesQuery + tab);
                }
            }
        },
        selectedCountriesChanged(data) {
            this.checkQueryStatus();
            this.filterClicked();
        },
        changeOrdering(data) {
            this.groupOrdersBy = data.id;
            this.groupingIsDefault = false;
            this.query.groupBy = data.id
            this.$refs.DashboardOrdersTable.getData();

        },
        changeSalesOrdering(data) {
            this.groupBySku = data.id;
            this.groupingIsDefault = false;

            switch (data.id) {
                case 'bySku':
                    this.query.groupBy = 'nm_id';
                    break;
                case 'brand':
                    this.query.groupBy = 'brand';
                    break;
                default:
                    this.query.groupBy = 'noGrouping';
            }

            this.$refs.DashboardProductsTable.getData();
        },
        formatOrderingState(state) {
            var $state = 'Группировать по'
            if (!state.id) {
                return state.text;
            } else if (state.id === 'noGrouping') {
                return $state;
            } else {
                return state.text.toLowerCase();
            }
        },
        selectAllChanged(status, page) {
            this.query.selectAll = this.queryCache.selectAll = status;
            this.query.pageSearch = this.queryCache.pageSearch = page;
        },
        selectedProductsChanged(data) {
            this.queryCache.products = data.productIds;
            if (typeof data.checkQuery !== 'undefined' && data.checkQuery === false)
                return false;
            this.checkQueryStatus()
        },
        copyText: function (text, context) {
            const el = document.createElement('textarea');
            el.value = text;
            el.innerText = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            if (context) {
                context.parentNode.insertBefore(el, context);
            } else {
                document.body.appendChild(el);
            }

            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            el.parentNode.removeChild(el);
            //document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
        },
        carousel() {
            // swiper element
            let swiperEl = document.querySelector('swiper-container');
            this.slider = swiperEl;

            // swiper parameters
            const swiperParams = {
                slidesPerView: 1,
                centerInsufficientSlides: true,

                breakpoints: {
                    1081: {
                        slidesPerView: 4,
                    },
                },

                injectStyles: [
                    `
                    @media (min-width: 1081px) {
                        :host(.panels-carousel) .swiper {
                            overflow: visible;
                        }
                    }

                    ::slotted(swiper-slide) {
                        height: auto;
                    }
                    `,
                ],
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperEl, swiperParams);

            // and now initialize it
            swiperEl.initialize();

            this.currentRangeSet.ranges.forEach((range, i) => {
                if (range.id === this.currentRangeSet.default.id)
                    swiperEl.swiper.slideTo(i);
            });

            const tippyList = $('[data-tippy_html]');

            swiperEl.swiper.on('slideChange', (event) => {
                this.currentRangeChanged(this.dataByPeriods[event.activeIndex].period);
                setTimeout(() => {
                    $('.day_item_outer').each(function () {
                        let dayItem = $('.day_block_item');
                        if ($(this).hasClass('active')) {
                            $('.day_item_outer').find(dayItem).removeClass('active');
                            $(this).find(dayItem).addClass('active');
                        }
                    })
                }, 200);

                tippyList.each(function () {
                    if (typeof this._tippy !== 'undefined')
                        this._tippy.hide();
                });
            });

        },
        goToSlide(index) {
            this.slider.swiper.slideTo(index);
        },
        jsInit() {
            let clickHandler = 'click';

            if ('ontouchstart' in document.documentElement) {
                clickHandler = 'touchend';
            }

            $(document).on(clickHandler, '[data-product-show-more]', function () {
                let parentContainer = $(this).parents('[data-product-cell]');
                parentContainer.find('[data-product-show-more-element]').removeClass('hidden');
                parentContainer.find('[data-product-show-more]').addClass('hidden');
            })
            $(document).on(clickHandler, '[data-product-hide-more]', function () {
                let parentContainer = $(this).parents('[data-product-cell]');
                parentContainer.find('[data-product-show-more-element]').addClass('hidden');
                parentContainer.find('[data-product-show-more]').removeClass('hidden');
            })

            // if (typeof window.refs == 'undefined')
            //     window.refs = {DashboardProductsTable: this.$refs.DashboardProductsTable};
            // else
            //     window.refs.DashboardProductsTable = this.$refs.DashboardProductsTable;
            //
            //
            // $(document).on(clickHandler, '.popover_status' , null, (event) => {
            //     console.log('.popover_status Clicked')
            //     event.stopPropagation();
            //     const productId = $(event.currentTarget).data('id');
            //     window.refs.DashboardProductsTable.setCost(productId, $('#currency-field' + productId).val());
            // });

            //table  More popovers

            let popoversForm = $('[data-responsive-table]'),
                tooltipParent = popoversForm.find('.price_popover'),
                toggleSwitch = tooltipParent.find('.open_price_popover');

            let moreTooltipParent = popoversForm.find('.more_popover_outer'),
                moreToggleSwitch = moreTooltipParent.find('.open_more_popover');

            $(document).on(clickHandler, '.open_more_popover', function (evt) {
                evt.preventDefault();
                let mSelf = $(this);
                moreTooltipParent.not(mSelf.parent()).removeClass('active');
                if (!mSelf.parent().hasClass('active')) {
                    mSelf.parent().addClass('active');
                    return false;
                } else {
                    mSelf.parent().removeClass('active');
                    return false;
                }
            });

            $(document).on(clickHandler, '.copy_button', null, (el) => {
                this.copyText($(el.currentTarget).attr('data-id'), el.currentTarget)

                $('#notificationMessage').fadeIn();

                setTimeout(function () {
                    $('#notificationMessage').fadeOut();
                }, 1500);

            });

            this.$el.addEventListener('data-is-loading', (value) => {
                console.log(value);
            });

            console.log('DashboardJsMounted');
        },
        toggleHeaderTab(tab = '') {
            this.queryCache.tab = tab;
            this.filterClicked();
        },
        downloadReport() {
            if (this.isDownloading)
                return false;

            let url = '/dashboard/export/products';

            if(this.section === 'orders')
                url = '/dashboard/export/orders';

            let self = this;
            this.isDownloading = true;

            axios.post(url, this.query, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'report.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    self.isDownloading = false;
                }).catch(error => {
                console.error(error);
                self.isDownloading = false;
            });
        },
        showNotification: function (message, type = 'info') {
            let self = this;
            this.notification.show = true;
            this.notification.message = message;
            this.notification.type = type;
            setTimeout(function () {
                self.notification.show = false;
            }, 3000);
        },
        fileUpload(files) {
            files.forEach((file) => {
                const filename = file.name;
                if (filename !== '') {
                    this.filename = filename;
                    const formData = new FormData();
                    formData.append('file', file);
                    const headers = {'Content-Type': 'multipart/form-data'};
                    axios.post(this.route('dashboardImport'), formData, {headers}).then((res) => {
                        this.filename = '';

                        if (res.data.status === true) {
                            this.showNotification(res.data.msg);
                            this.fileLoaded.push(filename)
                        } else {
                            this.fileErrors.push(filename)
                            this.showNotification(res.data.msg, 'error');
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.fileErrors.push(filename);
                        this.showNotification('Неправильный формат файла', 'error');

                    });
                }
            })
        }
    },

    mounted() {
        this.products.selected.forEach((product) => {
            this.productsSelected.push(product.id)
        });
        this.carousel();

        if (typeof window.isDashboardJsMounted == 'undefined') {
            try {
                this.jsInit();
                window.isDashboardJsMounted = true;
            } catch (e) {
                console.log(e);
            }
        }
        setTimeout(() => {
            this.filterCountries.forEach(c => {
                this.select2Countries.push({
                    id: c,
                    text: c
                })
            });

        }, 200);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let url = window.location.href;
        if (!url.includes('?') || urlParams.get('tab') != 'grid') {
            this.filterClicked();
        }

        const clientFilterData = localStorage.getItem(this.filterClientDataNS);
        if (clientFilterData) {
            const filterData = JSON.parse(clientFilterData);
            this.pageSearch = filterData.page;
            this.selectAll = filterData.selectAll;
        }

        if (!this.externalDataLoading) {
            this.getDataByPeriods(true);
        }
    },
    watch: {
        groupByArticul: function (newValue, oldValue) {
            this.query.page = 1;
            if (newValue === true) {
                this.query.groupBy = 'nm_id';
                this.groupBySku = 'bySku';
                this.groupOrdersBy = 'bySku';
            } else {
                this.query.groupBy = '';
                this.groupBySku = '';
            }
            this.groupingIsDefault = false;
        },
        groupOrdersBy: function (newValue, oldValue) {
            this.query.page = 1;
            this.query.groupBy = newValue;
            this.groupingIsDefault = false;
            // if (newValue === 'noGrouping')
            //     this.query.groupBy = '';
            // else

        },
        groupBySku: function (newValue, oldValue) {
            this.query.page = 1;
            if (newValue === 'bySku' || newValue === 'nm_id')
                this.query.groupBy = 'nm_id';
            else if (newValue === 'brand')
                this.query.groupBy = 'brand';
            else
                this.query.groupBy = '';

            this.groupingIsDefault = false;
        },
        externalDataLoading: function (value) {
            if (!value) {
                this.getDataByPeriods(true);
            }
        },
        'accountInfo.report_loaded': function (value) {
            this.reportLoaded = !!value;
        },
        'accountInfo.orders_updating': function (value) {
            if (value === 0) {
                this.getDataByPeriods(false);
            }
        },
        'accountInfo.orders_updating_first_month': function (value) {
            if (value === 0) {
                this.getDataByPeriods(false);
            }
        },
        sort: {
            deep: true,
            handler: throttle(function () {
                let self = this;
                this.$inertia.get('/dashboard', pickBy({
                    orderBy: self.sort.orderBy,
                    desc: self.sort.desc,
                }), {
                    replace: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.products = this.data.data;
                    }
                })
            }, 150),
        },
        query: {
            deep: true,
            handler: function (val) {
                localStorage.setItem(this.filterClientDataNS, JSON.stringify(val));
            },
        },
    },
}
</script>
