<template>
    <div class="multi-select" v-click-outside="onClickOutside">
        <div v-show="!isInline" class="multi-select__input" @click="openMultiselect()">
            <div class="multi-select__label">Товары</div>
        </div>
        <div v-show="!isInline && form.products.length" class="multi-select__selected__count">
            <span>Выбрано: {{ form.products.length }} продуктов</span>
<!--            <span class="fm-close" @click="clearFormProducts"></span>-->
        </div>

        <div class="multi-select__dropdown" v-show="multiselectOpen">
            <div class="multi-select__dropdown__header multi-select__dropdown__header_flex">
                <i class="multi-select__dropdown__header__ico fm-search"></i>
                <input class="multi-select__search" ref="searchInput" @input="selectorProductsSearch()" v-model="selectorProducts.search" type="text" placeholder="Поиск по имени, баркоду или артикулу">
                <button v-if="!loadingProductsStatus" class="multi-select__choose-all" type="button" @click.prevent="selectAllAction()">
                    {{ !selectAllStatus ? 'Выбрать все' : 'Сбросить'}}
                </button>
            </div>
            <div class="multi-select__dropdown__body" v-show="!selectorProducts.isLoading">
                <template v-for="(model, key) in selectorProducts.data.results">
                    <productMultiselectAccordion v-bind:isSingle="model.products.length === 1">
                        <template v-if="model.products.length > 1" v-slot:header>
                            <div class="input">
                                <div class="input__inner">
                                    <input class="input__checkbox" data-multiselect-check-all @change="triggerSelectedAll(key, $event)" :checked="model.selected" :value="key" :id="'pp-'+model.id" type="checkbox">
                                    <label class="input__checkbox-label" :for="'pp-'+model.id"></label>
                                </div>
                            </div>
                            <div class="multi-select__item__header__content">
                                <img class="multi-select__item__img" :src="model.image" width="41" height="41" alt="">
                                <div class="multi-select__item__header__content__body">
                                    <ProductTitle :title="model.text" css-classes="multi-select__item__title"/>
                                    <div class="multi-select__item__subtitle">
                                        {{ model.nm_id}}
                                        <span v-if="model.supplier_article"> / {{model.supplier_article}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else v-slot:header>
                            <div class="input">
                                <div class="input__inner">
                                    <input class="input__checkbox" v-model="form.products" @change="triggerChange" :value="model.id" :id="'pp-'+model.id" type="checkbox">
                                    <label class="input__checkbox-label" :for="'pp-'+model.id"></label>
                                </div>
                            </div>
                            <label :for="'pp-'+model.id" class="multi-select__item__header__content">
                                <img class="multi-select__item__img" :src="model.image" width="41" height="41" alt="">
                                <div class="multi-select__item__header__content__body">
                                    <ProductTitle :title="model.text" css-classes="multi-select__item__title"/>
                                    <div class="multi-select__item__subtitle">
                                        {{ model.nm_id }}
                                        <span v-if="model.supplier_article"> / {{model.supplier_article }}</span>
                                        / {{model.barcode}}</div>
                                </div>
                            </label>
                        </template>

                        <template v-if="model.products.length > 1" v-slot:content>
                            <div v-for="(child, k) in model.products" class="input">
                                <div class="input__inner">
                                    <input class="input__checkbox" @change="checkSelectedAll(key)" v-model="form.products" :value="child.id" :id="'p-'+child.id" type="checkbox">
                                    <label class="input__checkbox-label" :for="'p-'+child.id">
                                        {{ child.text }}
                                    </label>
                                </div>
                            </div>
                        </template>

                    </productMultiselectAccordion>
                </template>
                <div v-show="selectorProducts.data.pagination.more || selectorProducts.isShowMore" class="show-more-container" style="text-align:center;">
                    <button v-show="!selectorProducts.isShowMore && showMore" type="button" class="btn-default" @click="selectorProductsShowMore()">Показать больше</button>
                    <span class="multi-select__loading" v-show="selectorProducts.isShowMore">
                        <span class="spinner-border" role="status">
                            <span class="visually-hidden">Загрузка...</span>
                        </span>
                    </span>
                </div>
            </div>

            <div v-show="!selectorProducts.isLoading && selectorProducts.noData" class="empty_banner">
                <div class="empty_banner_search">
                    <span class="fm-search"></span>
                </div>
                <h4 class="empty_banner_title">Ничего не найдено</h4>
            </div>

            <div class="multi-select__loading" v-show="selectorProducts.isLoading">
                <span class="spinner-border" role="status">
                    <span class="visually-hidden">Загрузка...</span>
                </span>
            </div>

            <!--
            <div class="multi-select__footer hidden lg:block">
                <button class="btn-default btn-regular" @click="filterStart" type="button">Фильтровать</button>
            </div>
            -->
        </div>
    </div>
</template>

<script>
import productMultiselectAccordion from '@/Components/Expenses/productMultiselectAccordion'
import { pickBy } from 'lodash-es'
import ProductTitle from '@/Components/Products/Title.vue'
import { isProxy,toRaw } from 'vue';

export default {
    components: {
        ProductTitle,
        productMultiselectAccordion
    },

    data() {
        return {
            form: this.$inertia.form({
                "products": [],
            }),
            multiselectOpen: false,
            cancelTokenSource: null,
            selectorProducts: {
                request: false,
                isShowMore: false,
                isLoading: false,
                page: 1,
                search: '',
                is_grouped: 1,
                data: {
                    results: null,
                    pagination: {
                        isMore: false
                    }
                }
            },
            selectorProductsSearchTimeoutId: null,
            propsChanger: 'current',
            productsFull: [],
            selectAllStatus: false,
            loadingProductsStatus: null
        };
    },
    props: [
        'products',
        'isInline',
        'hidden',
        'selectAll',
        'page',
        'showMore'
    ],
    emits: ['changed', 'submit','selectAllChanged'],
    watch: {
        products: function(newValue, oldValue) {
            this.form.products = this.products;
        },
    },
    mounted() {
        this.form.products = this.products;
        if(this.selectAll === true){
            this.selectAllStatus = true;
            if(this.page > 1) {
                for (let i = 1; i <= this.page; i++) {
                    this.selectorProductsShowMore();
                }
            }
        }

        let box = document.getElementsByClassName('multi-select__dropdown__body')[0]

        if (this.showMore) {
            box = document.getElementById("addForm").getElementsByClassName("modal-body")[0];
        }
        box.addEventListener('scroll', (e) => {
            const scrollableHeight = box.scrollHeight - box.clientHeight

            if (box.scrollTop >= scrollableHeight) {
                if (this.selectorProducts.data.pagination.more) {
                    this.selectorProductsShowMore();
                }
            }
        })
    },
    methods: {
        mobileEventAdd(id) {
            const box = $('#' + id + ' .modal-body')[0];
            if (box) {
                box.addEventListener('scroll', (e) => {
                    const scrollableHeight = box.scrollHeight - box.clientHeight

                    if (box.scrollTop >= scrollableHeight) {
                        if (this.selectorProducts.data.pagination.more) {
                            this.selectorProductsShowMore();
                        }
                    }
                })
            }
        },
        destroyMobileEventAdd(){
            const box = $('#filterModal .modal-body')[0];
            removeEventListener('scroll',box);
        },
        getProductsList(productIds){
            this.productsFull.forEach((product, idx) => {
                if (!productIds.includes(product.id))
                    this.productsFull.splice(idx, 1);
            });
            if (!this.selectorProducts.length)
                return this.productsFull;
            this.selectorProducts.data.results.forEach((product) => {
                product.products.forEach((p) => {
                    //check products in childs
                    if (productIds.includes(p.id))
                        //exclude duplicates
                        if (!this.productsFull.some(e => e.id === p.id))
                            this.productsFull.push(p);
                })
            });
            return this.productsFull;
        },
        getGroupedProducts(products){
            let productsGrouped = [];
            productsGrouped = _.groupBy(products, "nm_id");
            productsGrouped = Object.keys(productsGrouped).map((key) => {
                return productsGrouped[key]
            })
            // products.forEach((product) => {
            //     if (!productsGrouped.length)
            //         productsGrouped.push(product);
            //     productsGrouped.forEach((productGrouped) => {
            //         if (productGrouped.nm_id === product.nm_id){
            //             if (typeof productGrouped.products === 'undefined')
            //                 productGrouped.products = [];
            //             productGrouped.products.push(product);
            //         }
            //
            //         else
            //             productGrouped.push(product);
            //     })
            // });
            return productsGrouped;
        },
        triggerChange(checkQuery = true){
            let productIds = this.excludeZero(this.form.products);
            let productsList = this.getProductsList(productIds);
            let data = {
                checkQuery: checkQuery,
                productIds: productIds,
                products: productsList,
                productsGrouped: this.getGroupedProducts(productsList)
            };
            this.$emit('changed', data);
            return data;
        },
        filterStart(status = true){
            this.triggerChange(status);
            this.$emit('submit');
        },
        triggerSubmit(){
            this.$emit('submit');
        },
        excludeZero(products){
            let newProducts = []
            products.forEach((productId) => {
                if (parseInt(productId) > 0)
                    newProducts.push(productId)
            });
            return newProducts;
        },
        clearFormProducts(){
            this.form.products = [];
            $('[data-multiselect-check-all]').prop('checked', false);
            let checkQuery = true;
            if (!this.multiselectOpen)
                checkQuery = false;

            this.filterStart(checkQuery);

            if (!this.multiselectOpen)
                this.triggerSubmit();
        },
        triggerSelectedAll(key, event){
            let selectAll = $(event.target).prop('checked');
            let emptyProducts = (!this.form.products || !this.form.products.length);
            this.selectorProducts.data.results[key].products.forEach((product, index) => {
                if (emptyProducts && selectAll){
                    this.form.products.push(product.id);
                }
                else{
                    this.form.products.forEach((prodId, idx) => {
                        if (selectAll){
                            if (!this.form.products.length || !this.form.products.includes(product.id))
                                this.form.products.push(product.id)
                        }
                        else{
                            if (product.id == prodId){
                                this.form.products.splice(idx, 1);
                            }

                        }
                    });
                }

            });
            this.triggerChange();
        },
        checkSelectedAll(key,notTrigger = false){
            let selectAll = true;
            this.selectorProducts.data.results[key].products.forEach((product, index) => {
                if (!this.form.products.length || !this.form.products.includes(product.id)) {
                    selectAll = false;
                }
            });

            this.selectorProducts.data.results[key].selected = selectAll;
            if(!notTrigger){
                this.triggerChange();
            }
        },
        onClickOutside() {
            if (!this.isInline)
                this.multiselectOpen = false;
        },
        selectAllAction(){
            let inputs = $(document).find('.multi-select__item input:not([data-multiselect-check-all])');
            let inputsWithChildren = $(document).find('.multi-select__item input[data-multiselect-check-all]');

            this.form.products = [];
            if (!this.selectAllStatus) {
                inputs.each((index, element) => {
                    this.form.products.push(element.value);
                });
                inputsWithChildren.each((index, element) => {
                    element.checked = true;
                });
            } else {
                inputsWithChildren.each((index, element) => {
                    element.checked = false;
                });
            }

            this.selectAllStatus = !this.selectAllStatus;
            this.$emit('selectAllChanged', this.selectAllStatus,this.selectorProducts.page);
            this.triggerChange();
        },
        checkSelectedAllProducts() {
            let inputsWithChildren = $(document).find('.multi-select__item input[data-multiselect-check-all]');
            inputsWithChildren.each((index, element) => {
                if(!element.checked) {
                    this.checkSelectedAll(element.attributes.value.value, true);
                }
            });

            let inputs = $(document).find('.multi-select__item input:not([data-multiselect-check-all])');
            if (inputs.length <= this.form.products.length && inputs.length > 0) {
                this.selectAllStatus = true;
            } else {
                this.selectAllStatus = false;
            }
        },
        openMultiselect(forceOpen = false) {
            let self = this;
            if (!forceOpen && this.multiselectOpen){
                this.multiselectOpen = false;
                return true;
            }

            this.multiselectOpen = true;
           // this.selectorProducts.page = 1;
            setTimeout(function(){
                self.$refs.searchInput.focus();
            }, 200)

            this.selectorProductsShowMore(true);

        },
        selectorProductsShowMore(isLoading){
            this.$emit('selectAllChanged', this.selectAllStatus,this.selectorProducts.page);
            if (this.selectorProducts.request){
                this.cancelTokenSource.cancel();
            }
            if (isLoading)
                this.selectorProducts.isLoading = true;
            else
                this.selectorProducts.isShowMore = true;
            this.cancelTokenSource = axios.CancelToken.source();
            this.selectorProducts.request = true;

            axios.get(this.route('expenses.productsCustom'), {
                cancelToken: this.cancelTokenSource.token,
                params: pickBy({
                    search: this.selectorProducts.search,
                    page: this.selectorProducts.page,
                    is_grouped: this.selectorProducts.is_grouped,
                    productIds: this.form.products,
                    hidden:this.hidden ? this.hidden : 0,
                })
            }).then((response) => {
                if (this.selectorProducts.page === 1)
                    this.selectorProducts.data = response.data;
                else{
                    this.selectorProducts.data.pagination = response.data.pagination;
                    this.selectorProducts.data.results = this.selectorProducts.data.results.concat(response.data.results);
                }

                if (!this.selectorProducts.data.results.length)
                    this.selectorProducts.noData = true;
                else
                    this.selectorProducts.noData = false;
                this.selectorProducts.page += 1;
                this.selectorProducts.isLoading = false;
                this.selectorProducts.isShowMore = false;
                setTimeout(() => {
                    this.checkSelectedAllProducts();
                }, 100);
            });
        },
        selectorProductsSearch(){
            let self = this;
            if (this.selectorProducts.search.length === 0 || this.selectorProducts.search.length > 3){
                this.selectorProducts.page = 1;
                if (this.selectorProductsSearchTimeoutId)
                    clearTimeout(this.selectorProductsSearchTimeoutId);
                this.selectorProductsSearchTimeoutId = setTimeout(function(){
                    self.selectorProductsShowMore(true);
                }, 500);
            }

        },
    }
}
</script>
