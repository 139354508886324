<template>
    <li class="notification" v-click-outside="hideNotification">
        <button class="notification__trigger" type="button" @click="toggleNotification">
            <i class="fm-bell"></i>
            <span v-show="newNotificationsCount" class="notification__counter">{{ newNotificationsCount }}</span>
        </button>

        <div class="notification-list" v-show="show && notifications.length">
            <div class="notification-list__inner">
                <div class="notification-list__items">
                    <div v-for="notification in notifications" class="notification-item" :class="{'notification-item_new': !notification.seen}">
                        <div class="notification-item__ico"><i class="fm-alert-triangle"></i></div>
                        <div class="notification-item__body">
                            <div class="notification-item__title">{{ notification.title }}</div>
                            <div class="notification-item__content">{{ notification.message }}</div>
                        </div>
                        <a v-if="notification.link" class="notification-item__link" :href="notification.link"><i class="fm-arrow-right"></i></a>
                    </div>
                </div>
                <div v-if="displayShowMore" class="notification-list__footer">
                    <button class="btn-default btn-regular" type="button">Показать больше</button>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import axios from "axios";

export default {
    props: ['notifications'],

    data() {
        return {
            show: false,
            displayShowMore: false
        }
    },

    computed: {
        newNotificationsCount: function () {
            return this.newNotifications.length;
        },
        newNotifications: function () {
            return this.notifications.filter(item => !item.seen);
        }
    },

    watch: {
        show(newShow, oldShow) {
            const markAsSeenIds = this.newNotifications.map(notification => notification.id);
            if (markAsSeenIds.length && !newShow && oldShow) {
                axios.post('/notifications/mark-as-read', {'ids': markAsSeenIds});
            }
        }
    },

    methods: {
        toggleNotification: function(){
            this.show = !this.show;
        },

        hideNotification: function(){
            this.show = false;
        },
    }
}
</script>
