<template>
    <div class="modal modal-fullscreen cost-price-modal" id="costPrice" data-costprice-modal aria-labelledby="costprice-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <form class="modal-content" @submit.prevent="updateCost" autocomplete="off">
                <div class="modal-header">
                    <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <h5 class="modal-title" id="costprice-modal-label">Себестоимость (нетто)</h5>
                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <span class="fm-close"></span>
                    </button>
                </div>

                    <input type="hidden" v-model="form.id">
                    <div class="modal-body scrollbar">
                        <div class="info-list">
                            <div class="info-list__header">
                                <img class="info-list__header__img" :src="product.image" width="40" height="40" alt="title">
                                <div class="info-list__header__body">
                                    <ProductTitle :title="product.title" :warning="true" css-classes="info-list__header__title"/>
                                    <div>
                                        <span class="item__product-id">
                                            <button class="copy-btn" @click="__copy(product.nm_id)" data-id="" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                            <a v-if="product.link" class="item__link" :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                            <span v-else>{{ product.nm_id }}</span>
                                        </span><span v-if="!forceApplyAll"> / {{ product.barcode }}</span>
                                    </div>
                                    <div v-if="!forceApplyAll">Цена: {{ product.price }}&nbsp;₽</div>
                                </div>
                            </div>

                            <div class="info-list__input info-list__input_last">
                                <div class="costprice-modal__label">Тип</div>
                                <div class="options-group">
                                    <div class="input">
                                        <div class="input__inner">
                                            <input class="input__hidden" v-model="form.cost_type" value="0" id="all-time" type="radio" name="type" checked>
                                            <label class="input__radio-btn" for="all-time">
                                                За всё время
                                            </label>
                                        </div>
                                    </div>

                                    <div class="input">
                                        <div class="input__inner">
                                            <input class="input__hidden" v-model="form.cost_type" value="1" id="by-period" type="radio" name="type">
                                            <label class="input__radio-btn" for="by-period">
                                                По периодам
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="form.cost_type == 0">
                                <div class="info-list__input" v-show="form.cost_type == 0">
                                    <div class="info-list__input__inner">
                                        <label class="info-list__input__label" for="cp-1">Себестоимость</label>
                                        <input class="info-list__input__text-input" data-price id="cp-1" type="text"
                                               v-bind:value="form.cost"
                                               v-on:input="form.cost = $event.target.value"
                                               @focusin="hideZeroCost(form.cost,$event)"
                                               @change="form.cost = $event.target.value"
                                               placeholder="0">
                                    </div>
                                </div>

                                <div class="info-list__input" v-if="product && products.length > 1">
                                    <div class="input__inner input__inner_info">
                                        <input class="input__checkbox" id="cp-for-all" v-model="form.apply_all" type="checkbox" :disabled="forceApplyAll">
                                        <label class="input__checkbox-label" for="cp-for-all">Использовать для всех баркодов этого артикула</label>
                                        <button class="input__info fm-info" type="button" aria-label="Подробнее" data-info>
                                            <div data-info_content style="display: none;">
                                                <div class="product-summary__details">
                                                    <div class="product-summary__header">
                                                        <img class="product-summary__img" :src="product.image" width="40" height="40" alt="product">
                                                        <div class="product-summary__header__inner">
                                                            <ProductTitle :title="product.title" :warning="true" css-classes="product-summary__product-title"/>
                                                            <div>{{ product.nm_id }}</div>
                                                        </div>
                                                    </div>
                                                    <div v-for="(item) in products" class="product-summary__variation">{{ item.barcode }} {{ (item.tech_size != '' && item.tech_size != 0 && item.tech_size != 'null') ? ', размер: '+item.tech_size : '' }}</div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>



                            <div v-show="form.cost_type == 1" class="costprice-modal__block costprice-modal__block_by-period">
                                <div v-show="!form.periods.length" class="costprice-modal__period-block">
                                    <div class="costprice-modal__initial-receipt">
                                        <div class="input input_date">
                                            <label class="input__label" for="cp-date-1">Дата поступления</label>
                                            <div class="input__inner">
                                                <input class="input__text-input" :class="periodDateError ? 'error' : ''" v-model="form.date" type="text" id="cp-date-1" data-date_input autocomplete="off">
                                                <label class="input__ico" for="cp-date-1"><span class="fm-bx-calendar"></span></label>
                                            </div>
                                        </div>

                                        <button type="button" aria-label="Close" class="btn-default btn-regular" @click="costPeriodsCreate">Добавить</button>
                                    </div>
                                </div>

                                <div v-for="(period, index) in form.periods" class="costprice-modal__period-block">
                                    <div class="input input_date">
                                        <label class="input__label" :for="'cp-from-'+index">Период с</label>
                                        <div class="input__inner">
                                            <input class="input__text-input"
                                                v-model="form.periods[index].from"
                                                type="text"
                                                :id="'cp-from-'+index"
                                                disabled
                                                placeholder="-∞"
                                                data-date_input autocomplete="off">
                                            <label class="input__ico" :for="'cp-from-'+index"><span class="fm-bx-calendar"></span></label>
                                        </div>
                                    </div>

                                    <div class="input input_date">
                                        <label class="input__label" :for="'cp-to-'+index">Период по</label>
                                        <div class="input__inner">
                                            <input class="input__text-input"
                                                v-model="form.periods[index].to"
                                                type="text" :id="'cp-to-'+index"
                                                disabled
                                                placeholder="+∞"
                                                data-date_input autocomplete="off">
                                            <label class="input__ico" :for="'cp-to-'+index"><span class="fm-bx-calendar"></span></label>
                                        </div>
                                    </div>

                                    <div class="input input_price">
                                        <label class="input__label" :for="'cp-cost-'+index">Себест. (нетто)</label>
                                        <div class="input__inner">
                                            <span class="input__currency">₽</span>
                                            <input class="input__text-input"
                                                   data-price
                                                   v-model="form.periods[index].cost"
                                                   :id="'cp-cost-'+index"
                                                   type="text"
                                                   placeholder="0"
                                                   @focusin="hideZeroCost(form.cost,$event)"
                                            >
                                        </div>
                                    </div>

                                    <button class="costprice-modal__period-block__close" @click="costPeriodsRemove(index)" type="button">
                                        <span class="fm-close"></span>
                                    </button>
                                </div>

                                <div v-if="product && products.length > 1" class="input costprice-modal__block_by-period__checkbox">
                                    <div class="input__inner input__inner_info input__inner_info_inline">
                                        <input class="input__checkbox" id="cp-for-all2" v-model="form.apply_all" type="checkbox" :disabled="forceApplyAll">
                                        <label class="input__checkbox-label" for="cp-for-all2">
                                            Использовать для всех баркодов этого артикула
                                            <button class="input__info fm-info" type="button" aria-label="Подробнее" data-info>
                                                <div data-info_content style="display: none;">
                                                    <div class="product-summary__details">
                                                        <div class="product-summary__header">
                                                            <img class="product-summary__img" :src="product.image" width="40" height="40" alt="product">
                                                            <div class="product-summary__header__inner">
                                                                <ProductTitle :title="product.title" :warning="true" css-classes="product-summary__product-title"/>
                                                                <div>{{ product.nm_id }}</div>
                                                            </div>
                                                        </div>
                                                        <div v-for="(item) in products" class="product-summary__variation">{{ item.barcode }} {{ (item.tech_size != '' && item.tech_size != 0 && item.tech_size != 'null') ? ', размер: '+item.tech_size : '' }}</div>
                                                    </div>
                                                </div>
                                            </button>
                                        </label>

                                    </div>
                                </div>

                                <div v-show="form.cost_type == 1 && form.periods.length" class="costprice-modal__add-date">
                                    <button class="btn-default" type="button" data-add_period>Добавить дату поступления</button>
                                </div>
                            </div>

                            <div class="info-list__input info-list__input_comment">
                                <label class="info-list__input__basic-label" for="cp-comment">Комментарий</label>

                                    <contenteditable tag="div" class="info-list__input__fake-textarea" id="cp-comment" contenteditable v-model="form.comment" :no-nl="true" :no-html="true" />

<!--                                <div class="info-list__input__fake-textarea" id="cp-comment" contenteditable="true" >-->
<!--                                    <span class="info-list__input__placeholder">{{ form.comment }}</span>-->
<!--                                </div>-->
                            </div>

                            <div class="info-list__input info-list__input_last">
                                <div class="input__inner">
                                    <input class="input__checkbox" id="hide-product" :checked="form.hidden == 1 ? true : false" @change="addToHide()" type="checkbox">
                                    <label class="input__checkbox-label" for="hide-product">Скрыть</label>
                                </div>
                            </div>

                        </div>


                        <!--
                        <div v-show="form.cost_type == 0" class="costprice-modal__block costprice-modal__block_all-time">
                            <div class="input input_price">
                                <label class="input__label" for="cp-1">Себест. (нетто)</label>
                                <div class="input__inner">
                                    <span class="input__currency">₽</span>
                                    <input class="input__text-input" v-model="form.cost" id="cp-1" type="text">
                                </div>
                            </div>

                            <div v-if="product && products.length > 1" class="input costprice-modal__block_all-time__checkbox">
                                <div class="input__inner input__inner_info">
                                    <input class="input__checkbox" id="cp-for-all" v-model="form.apply_all" type="checkbox" :disabled="forceApplyAll">
                                    <label class="input__checkbox-label" for="cp-for-all">Использовать для всех баркодов этого артикула</label>
                                    <button class="input__info fm-info" type="button" aria-label="Подробнее" data-info>
                                        <div data-info_content style="display: none;">
                                            <div class="product-summary__details">
                                                <div class="product-summary__header">
                                                    <img class="product-summary__img" :src="product.image" width="40" height="40" alt="product">
                                                    <div class="product-summary__header__inner">
                                                        <div class="product-summary__product-title">{{ product.title }}</div>
                                                        <div>{{ product.nm_id }}</div>
                                                    </div>
                                                </div>
                                                <div v-for="(item) in products" class="product-summary__variation">{{ item.barcode }} {{ (item.tech_size != '' && item.tech_size != 0 && item.tech_size != 'null') ? ', размер: '+item.tech_size : '' }}</div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div v-show="form.cost_type == 1" class="costprice-modal__block costprice-modal__block_by-period">
                            <div v-show="!form.periods.length" class="costprice-modal__period-block">
                                <div class="costprice-modal__initial-receipt">
                                    <div class="input input_date">
                                        <label class="input__label" for="cp-date-1">Дата поступления</label>
                                        <div class="input__inner">
                                            <input class="input__text-input" v-model="form.date" type="text" id="cp-date-1" data-date_input autocomplete="off">
                                            <label class="input__ico" for="cp-date-1"><span class="fm-bx-calendar"></span></label>
                                        </div>
                                    </div>

                                    <button type="button" aria-label="Close" class="btn-default btn-regular" @click="costPeriodsCreate">Добавить</button>
                                </div>
                            </div>

                            <div v-for="(period, index) in form.periods" class="costprice-modal__period-block">
                                <div class="input input_date">
                                    <label class="input__label" :for="'cp-from-'+index">Период с</label>
                                    <div class="input__inner">
                                        <input class="input__text-input"
                                               v-model="form.periods[index].from"
                                               type="text"
                                               :id="'cp-from-'+index"
                                               disabled
                                               placeholder="-∞"
                                               data-date_input autocomplete="off">
                                        <label class="input__ico" :for="'cp-from-'+index"><span class="fm-bx-calendar"></span></label>
                                    </div>
                                </div>

                                <div class="input input_date">
                                    <label class="input__label" :for="'cp-to-'+index">Период по</label>
                                    <div class="input__inner">
                                        <input class="input__text-input"
                                               v-model="form.periods[index].to"
                                               type="text" :id="'cp-to-'+index"
                                               disabled
                                               placeholder="+∞"
                                               data-date_input autocomplete="off">
                                        <label class="input__ico" :for="'cp-to-'+index"><span class="fm-bx-calendar"></span></label>
                                    </div>
                                </div>

                                <div class="input input_price">
                                    <label class="input__label" :for="'cp-cost-'+index">Себест. (нетто)</label>
                                    <div class="input__inner">
                                        <span class="input__currency">₽</span>
                                        <input class="input__text-input" v-model="form.periods[index].cost" :id="'cp-cost-'+index" type="text">
                                    </div>
                                </div>

                                <button class="costprice-modal__period-block__close" @click="costPeriodsRemove(index)" type="button">
                                    <span class="fm-close"></span>
                                </button>
                            </div>

                            <div v-if="product && products.length > 1" class="input costprice-modal__block_by-period__checkbox">
                                <div class="input__inner input__inner_info">
                                    <input class="input__checkbox" id="cp-for-all2" v-model="form.apply_all" type="checkbox" :disabled="forceApplyAll">
                                    <label class="input__checkbox-label" for="cp-for-all2">Использовать для всех баркодов этого артикула</label>
                                    <button class="input__info fm-info" type="button" aria-label="Подробнее" data-info>
                                        <div data-info_content style="display: none;">
                                            <div class="product-summary__details">
                                                <div class="product-summary__header">
                                                    <img class="product-summary__img" :src="product.image" width="40" height="40" alt="product">
                                                    <div class="product-summary__header__inner">
                                                        <div class="product-summary__product-title">{{ product.title }}</div>
                                                        <div>{{ product.nm_id }}</div>
                                                    </div>
                                                </div>
                                                <div v-for="(item) in products" class="product-summary__variation">{{ item.barcode }} {{ (item.tech_size != '' && item.tech_size != 0 && item.tech_size != 'null') ? ', размер: '+item.tech_size : '' }}</div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        -->

                    </div>
                    <div class="modal-footer">
                        <div v-show="form.cost_type == 1 && form.periods.length" class="costprice-modal__add-date-footer">
                            <button class="btn-default" type="button" data-add_period>Добавить дату поступления</button>
                        </div>
                        <button data-dismiss="modal" aria-label="Close" class="btn-default" type="button">Отменить</button>
                        <button type="submit" class="btn-default btn-regular">Сохранить</button>
                    </div>

            </form>
        </div>
    </div>

    <transition name="fade">
        <div id="notificationMessage" v-if="notification.show" :class="notification.type" class="notification-message is-display-block">
            <span>{{ notification.message }}</span>
        </div>
    </transition>

</template>

<script>
import contenteditable from 'vue-contenteditable'
import Inputmask from 'inputmask'
import ProductTitle from '@/Components/Products/Title.vue'

export default {
    components: {
        ProductTitle,
        contenteditable,
        Inputmask
    },
    props: [
        'reloadOnHideModal', 'productID'
    ],
    data() {
        return {
            form: this.$inertia.form({
                "id": null,
                "title": '',
                "cost_type": 0,
                "cost": 0,
                "apply_all": false,
                "force_apply_all": false,
                "date": null,
                "periods": [],
                "comment": '',
                "hidden":0,
            }),
            notification: {
                show: false,
                message: 'Успешно скопировано',
                type:'info',
            },
            periodDateError:false,
            periodCostError:false,
            product: {},
            products: {},
            forceApplyAll: false,
            preventHideModalReload: false,
            dateFormat: 'DD.MM.YYYY'
        };
    },
    mounted() {
        this.applyPricePlugin();
        this.tippyTooltips();
        this.init();

        if (this.productID) {
            this.costEdit(this.productID);
        }
    },
    emits: ['reload', 'closed'],
    methods: {
        hideZeroCost(cost, event){
            if (cost == 0){
                event.target.value = '';
            }
        },
        applyPricePlugin(){
            let amountElements = document.querySelectorAll('[data-price]');
            amountElements.forEach((el) => {
                Inputmask({
                    regex: "^[0-9.,]+$",
                    placeholder:' ',
                    showMaskOnHover:false,
                }).mask(el);
            })
        },
        costPeriodsRemove(index){
            if (this.form.periods.length === 2){
                this.form.periods = [];
                this.form.date = null;
                return false;
            }

            if (index === 0){
                this.form.periods[1].to = '+∞';
                this.form.periods[1].disabled = 'to';
            }
            else if (index === this.form.periods.length - 1){
                this.form.periods[1].from = '+∞';
                this.form.periods[1].disabled = 'from';
            }
            else{
                this.form.periods[index+1].to = this.form.periods[index].to;
            }


            this.form.periods.splice(index, 1);
        },
        costPeriodsCreate(){
            let self = this;
            this.periodDateError = false;
            if (!this.form.date) {
                this.periodDateError = true;
                this.showNotification('Дата поступления является обязательным полем', 'error');
                setTimeout(() => {
                    this.periodDateError = false;
                }, 5000)
                return false;
            }
            let periods = [];
            if (!this.form.periods.length){
                periods.push({
                    date: this.form.date,
                    cost: null,
                    from: this.form.date,
                    to: '+∞',
                    disabled: 'to'
                });
                periods.push({
                    date: this.form.date,
                    cost: null,
                    from: '-∞',
                    to: moment(this.formatDateSubmit(this.form.date)).subtract(1, "days").format(this.dateFormat),
                    disabled: 'from'
                });
            }
            else{
                let counter = 0;
                let periodPrev = null;
                let periodNext = null;
                let dateTime = null;
                let fromTime = null;
                let toTime = null;
                let periodsReversed = this.form.periods.slice().reverse();
                periodsReversed.forEach(function(period, index){
                    let periodCopy = JSON.parse(JSON.stringify(period));
                    dateTime = moment(self.formatDateSubmit(self.form.date)).format('X');
                    fromTime = moment(self.formatDateSubmit(period.from)).format('X');
                    toTime = moment(self.formatDateSubmit(period.to)).format('X');
                    counter++;
                    if (counter === 1 && dateTime <= toTime){
                        period.to = moment(self.formatDateSubmit(self.form.date)).subtract(1, "days").format(self.dateFormat);
                        period.cost = null;
                        periods.push(period);
                        periods.push({
                            date: self.form.date,
                            cost: periodCopy.cost,
                            from: moment(self.formatDateSubmit(self.form.date)).format(self.dateFormat),
                            to: periodCopy.to,
                            disabled: 'none'
                        });
                    }
                    else if (counter === self.form.periods.length && dateTime >= fromTime){
                        periods.push({
                            date: self.form.date,
                            cost: periodCopy.cost,
                            from: periodCopy.from,
                            to: moment(self.formatDateSubmit(self.form.date)).subtract(1, "days").format(self.dateFormat),
                            disabled: 'none'
                        });

                        period.from = moment(self.formatDateSubmit(self.form.date)).format(self.dateFormat);
                        period.cost = null;
                        periods.push(period);
                    }
                    else{
                        if (dateTime >= fromTime && dateTime <= toTime){
                            periods.push({
                                date: self.form.date,
                                cost: periodCopy.cost,
                                from: periodCopy.from,
                                to: moment(self.formatDateSubmit(self.form.date)).subtract(1, "days").format(self.dateFormat),
                                disabled: 'none'
                            });
                            periods.push({
                                date: self.form.date,
                                cost: null,
                                from: moment(self.formatDateSubmit(self.form.date)).format(self.dateFormat),
                                to: periodCopy.to,
                                disabled: 'none'
                            });
                        }
                        else{
                            periods.push(period);
                        }
                    }
                });
                periods = periods.slice().reverse();
            }

            this.form.periods = periods;

            setTimeout(function(){
                self.initDatePicker();
                self.initAddPeriodPicker();
                self.applyPricePlugin();
            }, 200)
        },
        updateCost: function (product) {
            let self = this;
            if (this.form.cost_type == 1 && !this.form.periods.length && !this.form.date){
                self.showNotification('Не внесена дата поступления. Информация не сохранена.');
                return false;
            }
            if (this.form.cost != '') {
                this.form.cost = this.form.cost.replace(',', '.');
            }
            else {
                this.form.cost = 0;
            }
            let checkCost = this.form.periods;
            checkCost.forEach((x, i) => {
                if (x.cost) {
                    let str = x.cost.toString();
                    this.form.periods[i].cost = str.replace(',', '.');
                } else {
                    this.periodCostError = true;
                    $('#cp-cost-' + i).addClass('error');
                    setTimeout(() => {
                        $('#cp-cost-' + i).removeClass('error');
                    }, 5000)
                }

            });
            if(this.form.cost_type == 1) {

                if (this.periodCostError) {
                    self.showNotification('Себестоймость не может быть 0', 'error');
                    this.periodCostError = false;
                    return false;
                }
            }

            let data = this.form.data();

            product.errors = {};
            axios.post('/product/cost', data)
                .then((response) => {
                    this.preventHideModalReload = true;
                    $('#product-cost-'+response.data.id).html(response.data.cost);
                    $('#costPrice').modal('hide');
                    this.form.date = null;
                    this.$emit('reload', {fullReload: true});
                    this.preventHideModalReload = false;

                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        self.showNotification('Обновите страницу и авторизуйтесь');
                        return false;
                    }

                    product.errors.cost = error.response.data.message;
                    self.showNotification('Неправильный формат данных. Информация не сохранена.','error');
                    // let productValid = this.getValidProductById(product.id);
                    // product.cost = productValid.cost;
                });
        },
        showNotification: function (message,type = 'info') {
            let self = this;
            this.notification.show = true;
            this.notification.message = message;
            this.notification.type = type;
            setTimeout(function () {
                self.notification.show = false;
            }, 3000);
        },
        populateEditForm: function (model, forceCostType = null, forceApplyAll = false) {
            this.form.id = model.id;
            this.form.title = model.title;
            this.form.cost = model.cost;
            if (forceCostType !== null)
                this.form.cost_type = forceCostType;
            else
                this.form.cost_type = model.cost_type;

            this.form.periods = model.costs
            if (forceApplyAll){
                this.forceApplyAll = true;
                this.form.apply_all = true
                this.form.force_apply_all = true
            }
            else {
                this.forceApplyAll = false;
                this.form.apply_all = !!model.cost_apply_all;
                this.form.force_apply_all = false
            }
            this.form.comment = (forceApplyAll) ? model.nm_comment : model.comment;
        },
        costEdit: function(id, forceCostType = null, forceApplyAll = false){
            setTimeout(function(){
                document.getElementById("cp-1").focus();;
            }, 500)

            let self = this;
            axios.get(this.route('product.info'), {
                params: {
                    id: id
                }
            })
                .then((response) => {
                    this.populateEditForm(response.data.product, forceCostType, forceApplyAll);
                    this.products = response.data.products;
                    this.product = response.data.product;
                    this.form.hidden = this.product.hidden;
                    setTimeout(function(){
                        $('#costPrice').modal('show');
                        self.initDatePicker();
                        self.initAddPeriodPicker();
                        self.tippyTooltips();
                        self.applyPricePlugin();
                    }, 200)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        self.showNotification('Обновите страницу и авторизуйтесь');
                        return false;
                    }

                    self.showNotification('Произошла ошибка, попробуйте снова.');

                });

        },

        initDatePicker: function () {
            let self = this;
            let pickerEl = $('[data-date_input]');
            pickerEl.each(function(){
                let picker = $(this).daterangepicker({
                    singleDatePicker: true,
                    showDropdowns: false,
                    autoApply: true,
                    minYear: 1901,
                    autoUpdateInput: false,
                    maxYear: parseInt(moment().format("YYYY"), 10),
                    //parentEl: $(this).parent(),
                    parentEl: $('#costPrice'),
                    locale: {
                        direction: 'ltr',
                        format: self.dateFormat,
                        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                        firstDay: 1
                    },
                    drops: 'bottom'
                });


                $(this).on('apply.daterangepicker', function (ev, picker) {
                    $(this).val(picker.startDate.format(self.dateFormat));
                    this.dispatchEvent(new Event('input'))
                });
            });

        },

        initAddPeriodPicker: function(){
            let self = this;
            $('[data-add_period]').daterangepicker({
                singleDatePicker: true,
                showDropdowns: false,
                autoApply: false,
                minYear: 1901,
                autoUpdateInput: false,
                maxYear: parseInt(moment().format("YYYY"), 10),
                parentEl: $('#costPrice'),
                locale: {
                    direction: 'ltr',
                    format: self.dateFormat,
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    firstDay: 1,
                    "applyLabel": "Добавить",
                    "cancelLabel": "Отменить",
                },
                // showCustomRangeLabel: true,
                drops: 'auto'
            });
            $('[data-add_period]').on('apply.daterangepicker', function(ev, picker) {
                self.form.date = picker.startDate.format(self.dateFormat);
                self.costPeriodsCreate();
            });
        },
        formatDate(dateString) {
            return moment(dateString).format(this.dateFormat);
        },
        formatDateSubmit(dateString) {
            let splitted = dateString.split('.');
            return splitted[2] + '-' + splitted[1] + '-' + splitted[0];
        },

        tippyTooltips() {
            if (!$('[data-info]').length)
                return false;
            let tippy_content = $('[data-info_content]').html();
            if (typeof $('[data-info]').get(0)._tippy !== 'undefined')
                $('[data-info]').get(0)._tippy.setContent(tippy_content);
            else
                tippy('[data-info]', {
                    content: tippy_content,
                    // trigger: 'mouseenter focus',
                    allowHTML: true,
                    // trigger: 'click',
                    // hideOnClick: false,
                    // maxWidth: 340,
                    //placement: 'bottom',
                    arrow: true,
                    zIndex: 9999
                });
        },
        addToHide(){
            this.form.hidden = !this.form.hidden;
        },
        init() {
            console.log('ProductsCostModal Mounted')
            let self = this;
            $(document).off('hide.bs.modal', '#confirmCostModal');
            $(document).on('hide.bs.modal', '#confirmCostModal',function (e) {
                if (self.reloadOnHideModal && !self.preventHideModalReload){
                    self.$emit('reload', {fullReload: true});
                }

            });

            $(document).off('hide.bs.modal', '#costPrice');
            $(document).on('hide.bs.modal', '#costPrice', function (e) {
                if (self.reloadOnHideModal && !self.preventHideModalReload){
                    self.$emit('reload', {fullReload: true});
                }

                self.$emit('closed');
            });
        },
    },

}
</script>
<style>
.input__text-input:focus::placeholder {
  color: transparent;
}
</style>
