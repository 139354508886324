<template>
    <tr v-show="!child || (child && showStatus)"
        :class="[(child ? 'table-accordion__content' : ''), (this.product.length > 1 ? 'products-grouped' : ''), (showChildrenStatus ? 'active' : '')]">
        <td>
            <div class="item">
                <button class="table-accordion__trigger" v-if="this.product.length > 1" v-on:click="showChildren()"
                        type="button" aria-label="Раскрыть/скрыть"
                        :aria-selected="showChildrenStatus ? true : false"></button>
                <img class="item__img" :src="product.image" width="40" height="40" :alt="product.title">
                <div class="item__body">
                    <div class="item__data item__data_mob">
                        <span class="item__product-id" v-if="!child">
                            <CopyButton :text-to-copy="String(product.nm_id)"/>
                            <a v-if="product.link" class="item__product-id__link" :href="product.link" target="_blank">{{product.nm_id }}</a>
                            <span v-else>{{ product.nm_id }}</span>
                        </span>

                        <ProductTitle :title="product.title" :warning="true"/>

                        <div>{{ product.barcode }}</div>
                    </div>

                    <div class="item__data item__data_desktop">
                        <ProductTitle :title="product.title" :warning="true"/>

                        <div class="whitespace-nowrap">
                            <span class="item__product-id">
                                <span v-if="!child">
                                <CopyButton :text-to-copy="String(product.nm_id)"/>
                                <a class="item__link" :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                    /
                                </span>
                                <span v-if="child || this.product.length === 1">{{ product.barcode }}</span>
                                <span> {{ formattedSize() }}</span>
                            </span>
                        </div>

                        <div class="whitespace-nowrap" v-if="!child">
                            <ProductCost
                                :product="product"
                                @product-updated="$emit('product-updated')"
                            />
                            <span v-if="product.discounted_price > 0"> / <span class="whitespace-nowrap">Цена: <Currency
                                :value="product.discounted_price"/></span></span>
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td>{{ product.quantity }}</td>
        <td>{{ product.quantity_reserved }}</td>
        <td>
            <Currency :value="product.warehouse_cost"/>
        </td>
        <td>{{ product.sales_speed }}</td>
        <td><span :class="quantityDaysClasses">{{ product.quantity_days }}</span></td>
        <td>
            <div class="table-input" v-if="child || inputOn">
                <label class="table-input__label">
                    <WarehouseParamInput option-key="intermediate_warehouse"
                                         :product="product"
                                         @product-updated="$emit('product-updated')"
                                         @notification="$emit('notification',$event)"/>
                </label>
            </div>
            <span v-else>
              {{ product.intermediate_warehouse }}
            </span>
        </td>
        <td>
            <div class="table-input" v-if="child || inputOn">
                <label class="table-input__label">
                    <WarehouseParamInput option-key="purchase"
                                         :product="product"
                                         @product-updated="$emit('product-updated')"
                                         @notification="$emit('notification',$event)"
                    />
                </label>
            </div>
            <span v-else>
              {{ product.purchase }}
            </span>
        </td>
        <td>
            <div class="table-input" v-if="child || inputOn">
                <label class="table-input__label">
                    <WarehouseParamInput option-key="order_days"
                                         :product="product"
                                         @product-updated="$emit('product-updated')"
                                         @notification="$emit('notification',$event)"
                    />
                </label>
            </div>
            <span v-else>
              {{ product.order_days ?? '----' }}
            </span>
        </td>
        <td><span :class="nextOrderDaysClasses">{{ product.next_order_days ?? '----' }}</span></td>
        <td>{{ product.recommend_to_order === 0 ? '-' : product.recommend_to_order }}</td>
        <td>
            <Percent :value="product.roi"/>
        </td>
        <td>
            <ProductDetailsModal
                :product="product"
                @product-updated="$emit('product-updated')"
                @notification="$emit('notification',$event)"
            />
        </td>
    </tr>

    <template v-if="this.product.length > 1">
        <WarehouseProductsTableRow
            v-for="childProduct in productList"
            :showStatus="showChildrenStatus"
            :key="childProduct.id"
            :child="true"
            :product="childProduct"
            @productUpdated="$emit('product-updated')"
            @show-more="$emit('show-more')"
            @notification="$emit('notification',$event)"
        />
    </template>

</template>

<script>
import Currency from '@/Components/Common/Currency'
import Percent from '@/Components/Common/Percent.vue'
import WarehouseParamInput from '@/Components/Warehouse/WarehouseParamInput.vue'
import CopyButton from '@/Components/Common/CopyButton.vue'
import ProductCost from '@/Components/Products/ProductCost.vue'
import ProductDetailsModal from '@/Components/Warehouse/ProductDetailsModal.vue'
import ProductTitle from '@/Components/Products/Title.vue'

export default {
    name: "WarehouseProductsTableRow",
    props: [
        'product',
        'showStatus',
        'child',
        'groupBy'
    ],
    components: {
        ProductTitle,
        ProductDetailsModal,
        ProductCost,
        CopyButton,
        WarehouseParamInput,
        Percent,
        Currency,
    },
    data() {
        return {
            inputOn:false,
            productList: [],
            showChildrenStatus: false,
        }
    },
    computed: {
        quantityDaysClasses() {
            return {
                'status-tag': true,
                'status-tag_danger': (this.product.quantity_days > 0 && this.product.quantity_days <= 7) || (this.product.sales_speed > 0 && this.product.quantity_days === 0),
                'status-tag_partial': this.product.quantity_days > 7 && this.product.quantity_days <= 14,
            }
        },
        nextOrderDaysClasses() {
            return {
                'status-tag': true,
                'status-tag_danger': this.product.next_order_days <= 0 && this.product.sales_speed > 0,
                'status-tag_partial': (this.product.next_order_days > 0 && this.product.next_order_days <= 7) && this.product.sales_speed > 0,
            }
        },
        product(){
            if (this.product.length > 1) {
                this.productList = this.product.slice(0, this.product.length - 1);
                return this.product[this.product.length - 1];
            } else if (Array.isArray(this.product) && this.product.length === 1) {
                this.inputOn = true;
                return  this.product[0];
            }
            else {
                this.inputOn = true;
            }
            return this.product;
        }
    },
    methods: {
        showChildren() {
            this.showChildrenStatus = !this.showChildrenStatus;
        },
        formattedSize() {
            if (this.product.size == '' || this.product.size == undefined || this.product.size == 0 || this.product.size == 'null') {
                return '';
            } else {
                return '/ размер: ' + this.product.size;
            }
        },
    },
    emits: ['product-updated', 'notification', 'show-more'],
}
</script>
