<template>
    <tr class="products-grouped">
        <td data-title="Наименование" @click="openChildren()">
            <div class="item">
                <button aria-label="Раскрыть/скрыть" class="table-accordion__trigger"
                        type="button"></button>
                <div class="inner-relative"><span>{{ user.name }}</span></div>
            </div>
        </td>
        <td>
            <div class="inner-relative"><span>{{ user.email }}</span></div>
        </td>
        <td>
            <div v-if="user.user_subscription" class="inner-relative"><span>{{
                    user.user_subscription.type ?? ''
                }}</span></div>
        </td>
        <td>
            <div v-if="user.user_subscription" class="inner-relative"><span
                :class="user.user_subscription.active ? 'text-green-500' : 'text-red-500'">{{
                    user.user_subscription.active ? 'Активный' : 'Неактивный'
                }}</span> <span v-show="user.user_subscription.trial">(Trial)</span></div>
        </td>
        <td>
            <div v-if="user.last_activity" class="inner-relative"><span>{{
                    user.last_activity.activity_date
                }} {{ user.last_activity.activity_time }}</span></div>
        </td>
        <td class="flex justify-center py-2 flex-column">
            <div class="inner-relative flex flex-column items-start">
                <span class="bg-blue-600 cursor-pointer block rounded px-3 py-1 text-white"
                      @click="generateLink(user)">Ссылка на вход</span>
                <div class="mt-2">{{ link }}</div>
                <span v-if="hasInvoice" class="bg-green-600 mt-2 block cursor-pointer rounded px-3 py-1 text-white"
                      @click="setActiveSubscription(user.user_subscription.payments[0].id)">Активировать подписку</span>
            </div>
        </td>
    </tr>

    <tr :class="{hidden: !accordion_opened}" class="table-accordion__content">
        <td colspan="6">
            <table class="table table-fixed table-fw-widget partners-table table-condensed dashboard-table">
                <thead>
                <tr>
                    <th class="nowrap" style="text-align: left;">
                        Название
                    </th>
                    <th class="nowrap" style="text-align: left;">
                        Ключ
                    </th>
                    <th class="nowrap" style="text-align: left;">
                        Последняя загрузка
                    </th>
                    <th class="nowrap" style="text-align: left;">
                        Статус
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(accountParent) in user.user_accounts_list_with_children">
                    <tr>
                        <td>
                            <div class="item">
                                <div class="inner-relative"><span>{{ accountParent.title }}</span></div>
                            </div>
                        </td>
                        <td class="w-2/5 break-words">
                            <span class="p-2 rounded bg-green-600 text-white" @click="getKey(accountParent)">Получить ключ</span>
                            <p class="my-2">{{ api_key[accountParent.account_id] }}</p>
                        </td>
                        <td>
                            <div class="inner-relative"><span>{{ accountParent.account.last_sync }}</span></div>
                        </td>
                        <td>
                            <div class="inner-relative"><span
                                :class="accountParent.account.status ? 'text-green-500' : 'text-red-500'">{{
                                    accountParent.account.status ? 'Активный' : 'Неактивный'
                                }}</span></div>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </td>
    </tr>

</template>
<script>
export default {
    data() {
        return {
            user: this.user,
            accordion_opened: false,
            link: '',
            api_key: [],
        }
    },
    emits: ['subscription-activated'],
    props: {
        user: {
            type: Object,
            required: true
        },
        hasInvoice: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        openChildren() {
            this.accordion_opened = !this.accordion_opened;
        },
        setActiveSubscription(id) {
            axios.post(route('admin.users.subscription.activate'), {
                subscription_id: id
            }).then(response => {
                this.$emit('subscription-activated');
            }).catch(error => {
                console.log(error);
            });
        },

        getKey(account){
            axios.post(route('admin.users.account.key'), {
                account_id: account.account_id
            }).then(response => {
                this.api_key[account.account_id] = response.data;
            }).catch(error => {
                console.log(error);
            });
        },

        generateLink(user) {
            axios.post(route('admin.users.login.generate'), {
                user_id: user.id
            }).then(response => {
                this.link = response.data;
            }).catch(error => {
                console.log(error);
            });
        }
    }
}
</script>
