<template>
    <div class="connection_title_section text-center my-3">
        <h1>Подключение магазина</h1>
    </div>

    <div class="add-marketplace" :class="{'single': hideWildberries || hideOzon}">
        <div class="add-marketplace__item" v-show="!hideWildberries">
            <img class="add-marketplace__logo" src="/img/wildberries.svg" height="18" alt="Wildberries" loading="lazy">
            <div class="add-marketplace__body">
                <ApiKeyField
                    ref="wildberriesApiKey"
                    slug="wildberries"
                    marketplace="Wildberries"
                    @field-changed="errors.wildberries = ''"
                    :submit-error="errors.wildberries"
                />
            </div>
            <ul class="add-marketplace__links" v-show="!hideHelpLinks">
                <li><a class="add-marketplace__links__link" href="/api-key">Где найти ключ API Wildberries?</a></li>
                <li><a class="add-marketplace__links__link" href="/api-key-error">Ключ Wildberries выдаёт ошибку?</a></li>
                <li><a class="add-marketplace__links__link" href="https://sellerdata.ru/api-key-entry-security/" target="_blank">Безопасность и данные</a></li>
            </ul>
        </div>
        <div class="add-marketplace__item" v-show="!hideOzon">
            <img class="add-marketplace__logo" src="/img/ozon.svg" height="18" alt="Ozon" loading="lazy">
            <div class="add-marketplace__body">
                <ApiKeyField
                    ref="ozonApiKey"
                    slug="ozon"
                    marketplace="Ozon"
                    @field-changed="errors.ozon = ''"
                    :submit-error="errors.ozon"
                />
            </div>
            <ul class="add-marketplace__links" v-show="!hideHelpLinks">
                <li><a class="add-marketplace__links__link" href="/ozon-connection">Где найти ключ API Ozon?</a></li>
                <li><a class="add-marketplace__links__link" href="/ozon-connection-error">Ключ Ozon выдаёт ошибку?</a></li>
                <li><a class="add-marketplace__links__link" href="https://sellerdata.ru/api-key-entry-security/" target="_blank">Безопасность и данные</a></li>
            </ul>
        </div>
    </div>

    <div class="add-marketplace-actions mb-3">
        <button
            class="btn-regular btn_lg relative"
            type="button"
            :disabled="isFormProcessing"
            @click.prevent="save"
        >
            Подключить
            <span class="btn-loader" v-show="isFormProcessing">
                <span class="btn-loader__inner">
                    <span class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </span>
                </span>
            </span>
        </button>

        <a :href="route(exitButtonRoute)" class="btn-default btn_lg">{{ exitButtonTitle }}</a>
    </div>
    <Modal
        v-if="errors.global"
        @close="resetErrors"
        ref="errorsModal"
        footerClasses="modal-footer_center"
    >
        <template #title>Ошибка</template>
        <template #body>
            <div class="text-center">
                <div v-html="errors.global"></div>
            </div>
        </template>
        <template #buttons>
            <button class="btn-regular" type="button" @click="closeModal">Ок</button>
        </template>
    </Modal>
</template>

<script>
import BreezeInput from '@/Components/Input.vue'
import ApiKeyField from '@/Components/Account/ApiKeyField.vue'
import Modal from '@/Components/Common/Modal.vue'
import axios from 'axios'
import apiKeyValidation from '@/Mixins/apiKeyValidation'

export default {
    name: 'ApiKeyForm',
    components: { Modal, ApiKeyField, BreezeInput },
    props: {
        hideWildberries: {
            type: Boolean,
            default: false,
        },
        hideOzon: {
            type: Boolean,
            default: false,
        },
        hideHelpLinks: {
            type: Boolean,
            default: false,
        },
        exitButtonTitle: {
            type: String,
            required: true,
        },
        exitButtonRoute: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isFormProcessing: false,
            apiKeys: {
                ozon: '',
                wildberries: '',
            },
        }
    },
    mixins: [apiKeyValidation],
    methods: {
        save() {
            this.isFormProcessing = true

            this.resetErrors();

            this.apiKeys.ozon = this.$refs.ozonApiKey.getValue();
            this.apiKeys.wildberries = this.$refs.wildberriesApiKey.getValue();

            if (!this.isValid()) {
                this.isFormProcessing = false
                return;
            }

            axios.post(this.route('account.new'), {
                wildberriesKey: this.apiKeys.wildberries,
                ozonKey: this.apiKeys.ozon
            }).then(response => {
                ym(87932746,'reachGoal','successful_key');

                document.location.href = response.data.goto;
            }).catch(error => {
                this.isFormProcessing = false;
                console.log(error);
            });
        },
        resetErrors() {
            this.errors = {
                global: '',
                ozon: '',
                wildberries: '',
            }
        },
        closeModal () {
            this.$refs.errorsModal.close();
        },
    }
}
</script>
