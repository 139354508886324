<template>
    <div v-if="!isHidden" class="modal modal-fullscreen" id="videoModal" tabindex="-1" aria-labelledby="videoModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <h5 class="modal-title" id="videoModalTitle">{{ __("global.hint." + currentRoute + ".title") }}</h5>
                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="showVideo" class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" :data-video-src="getEmbedUrl(__('global.hint.'+ currentRoute +'.video'))" src="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    {{ __("global.hint." + currentRoute + ".text") }}
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Понятно</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isHidden: false,
            currentRoute: 'dashboard',
            showVideo: true
        };
    },
    methods: {
        hidden() {
            this.isHidden = true;
            localStorage.setItem('hint_' + this.currentRoute, 'hidden');
        },
        getEmbedUrl(url){
            let videoId = null;
            let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            let match = url.match(regExp);
            if (match && match[2].length == 11) {
                videoId = match[2];
            }

            if (videoId)
                return '//www.youtube.com/embed/'+videoId+'?autoplay=1';
            return url;
        },
        jsInit(){
            $(document).off('hidden.bs.modal', '#videoModal');
            $(document).on('hidden.bs.modal', '#videoModal', function (e) {
                $('[data-video-src]').attr('src', '');
            })

            $(document).off('show.bs.modal', '#videoModal');
            $(document).on('show.bs.modal', '#videoModal', function (e) {
                $('[data-video-src]').attr('src', $('[data-video-src]').attr('data-video-src'));
            })
        }

    },
    mounted() {
        this.currentRoute = this.route().current();

        if (this.currentRoute === 'settings')
            this.showVideo = false;
        if (this.__("global.hint." + this.currentRoute + ".video") === ''
            || this.__("global.hint." + this.currentRoute + ".video") === "global.hint." + this.currentRoute + ".video"
        )
            this.showVideo = false;

        this.isHidden = !!localStorage.getItem('hint_' + this.currentRoute);
        this.jsInit();
    }
}
</script>
